// @ts-nocheck
import React from 'react';
import { Button } from 'antd';
import { Item } from '../store';

interface Properties {
    item: Item;
    selectedQuantity: number;
    onUpdateCart: (e: any, value: any, item: any) => void;
    commonStyles: any;
}

export const Counter = React.memo((props: Properties) => {
    const onIncrement = React.useCallback((e) => {
        props.onUpdateCart(e, props.selectedQuantity + 1, props.item);
    }, [props]);
    const OnDecrement = React.useCallback((e) => {
        props.onUpdateCart(e, props.selectedQuantity - 1, props.item);
    }, [props]);
    const counterStyle = React.useMemo(() => getCounterStyle(props.commonStyles), [props.commonStyles]);

    return (
        <div className="stepper-input" style={{ marginLeft: props.commonStyles.gridSize, display: 'flex', flexDirection: 'row' }}>
            {props.selectedQuantity > 1 && <Button 
                type="ghost" 
                size={'small'} 
                style={{ fontSize: props.commonStyles.fontSize.small, borderColor: props.commonStyles.fontColor.dark, marginRight: 3, color: props.commonStyles.fontColor.dark }} 
                onClick={OnDecrement}>
                {'Reduce Quantity'}
            </Button>}
            {props.selectedQuantity < props.item.quantity && <Button 
                type="ghost" 
                size={'small'} 
                style={{ fontSize: props.commonStyles.fontSize.small, borderColor: props.commonStyles.fontColor.dark, color: props.commonStyles.fontColor.dark }} onClick={onIncrement}>
                {'Add Quantity'}
            </Button>}
        </div>
    );
});

function getCounterStyle(commonStyles: any) {
    return {
        input: {
            fontFamily: commonStyles.glyphFamily,
            padding: 2,
            maxWidth: '50px',
            borderWidth: 0,
            borderColor: 'grey',
            color: commonStyles.fontColor.dark,
            fontSize: commonStyles.fontSize.medium,
            textAlign: 'right'
        },
        operator: {
            border:'1px solid grey',
            padding: commonStyles.gridSize * 0.7, 
            marginLeft: commonStyles.gridSize * 0.5,
            marginRight: commonStyles.gridSize * 0.5,
            fontFamily: commonStyles.glyphFamily,
            color: commonStyles.fontColor.light,
            textAlign: 'center'
        }
    };
}
