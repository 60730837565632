import { detect } from 'detect-browser';
import * as Sentry from '@sentry/react';

export const LogInfo = (msg: string) => {
    console.log(msg);
};

export const LogProfile = (page: string) => {
    let date = new Date();
    console.log(page + ' : ' + date.getMinutes() + ' ' + date.getSeconds() + ' ' + date.getMilliseconds());
};

export async function LogError(msg: any) {
    const browserInfo = getBrowserInfo();
    Sentry.setExtra('browserInfo', JSON.stringify(browserInfo));
    Sentry.setExtra('error', msg);
    Sentry.captureException(msg);
};

async function getBrowserInfo() {
    const browser = detect();

    if (browser) {
        return {
            name: browser.name,
            version: browser.version,
            os: browser.os,
            type: browser.type
        };
    }
   
    return {};
}
