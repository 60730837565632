import firebase from 'firebase';

export async function loginAnonymously() {
    if (!firebase.auth().currentUser) {
        const userCredential = await firebase.auth().signInAnonymously();
        return userCredential.user;
    }
    
    return firebase.auth().currentUser;
}

export async function signOutUser(user: firebase.User | null) {
    user?.delete();
}

export async function loginWithEmailAndPassword() {
    if (!firebase.auth().currentUser) {
        const userCredential = await firebase.auth().signInWithEmailAndPassword('onlinestore@naavo.in', 'Naavo07!*!');
        return userCredential.user;
    }
    
    return firebase.auth().currentUser;
}