// @ts-nocheck
/*eslint eqeqeq: "off"*/
import React from 'react';
import { MessageOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Company, Metadata } from '../store';

interface Properties {
  width: number;
  theme: any;
  style?: any;
  metadata: Metadata;
  company: Company;
  commonStyles: any;
}

// bad design to add modal here, changeit later

export const Footer = React.memo((props: Properties) => {
  const [state, setState] = React.useState(({ title: undefined, content: undefined, isVisible: false }));
  const isPresent = props.metadata && (props.metadata.AboutUs || props.metadata.ReturnAndRefundPolicy || props.metadata.ShippingPolicy || props.metadata.PaymentPolicy || props.metadata.PrivacyPolicy || props.metadata.TermsAndConditions);
  const isContactEnabled = (props.metadata.CallEnabled || props.metadata.WhatsappEnabled || props.metadata.MessageEnabled) && props.metadata.PhoneNo;
  const isEmailEnabled = props.metadata.emailEnabled && props.metadata.Email;
  const onLinkClick = React.useCallback((e: any, title: string, content: string) => {
    e.preventDefault();
    setState((state: any) => {
      return {
        ...state,
        isVisible: true,
        title,
        content
      };
    });
  }, [setState]);
  const onDismiss = React.useCallback(() => setState((state: any) => {
    return {
      ...state,
      isVisible: false,
    };
  }), [setState]);
  const onContactClick = React.useCallback((e: any, type: string) => {
    let title = ''; let content = '';
    if (type === 'call') {
      title = 'Call';
      content = `If you have any queries or feedback, please, call us at: ${props.metadata.PhoneNo}`;
    } else if (type === 'message') {
      title = 'Message';
      content = `If you have any queries or feedback, please, message us at: ${props.metadata.PhoneNo}`;
    } else if (type === 'whatsapp') {
      title = 'Whatsapp';
      content = `If you have any queries or feedback, you can whatsapp us at: ${props.metadata.PhoneNo}`;
    } else {
      title = 'Email';
      content = `If you have any queries or feedback, you can email us at: ${props.metadata.Email}`;
    }

    setState((state: any) => {
      return {
        ...state,
        isVisible: true,
        title,
        content
      };
    });
  }, [props.metadata, setState]);
  const onNewTabClick = React.useCallback((link: string) => window.open(link, '_newtab'), []);

  const footerStyle = React.useMemo(() => getFooterStyle(props.commonStyles), [props.commonStyles]);

  const showContactUs = true;
  const showOtherDetails = true;

  return (
    <>
      <div
        className="div-footer"
        style={{
          ...footerStyle.divFooter,
          color: props.commonStyles.footer.fontColor,
          backgroundColor: showContactUs ? props.commonStyles.footer.backgroundLigher : (showOtherDetails ? props.commonStyles.footer.backgroundDarker : props.commonStyles.backgroundColor)
        }}>
        {showOtherDetails && isPresent && <div style={{ cursor: 'pointer', fontWeight: 'bold', fontFamily: props.commonStyles.fontfamily, display: 'flex', flexDirection: props.width < 545 ? 'column' : 'row', justifyContent: 'space-around', padding: props.commonStyles.gridSize }}>
          {props.metadata.AboutUs && <div style={footerStyle.divAnchor} onClick={(e: any) => onLinkClick(e, 'About us', props.metadata.AboutUs)}>About Us</div>}
          {props.metadata.ReturnAndRefundPolicy && <div style={footerStyle.divAnchor} onClick={(e: any) => onLinkClick(e, 'Return And Refund Policy', props.metadata.ReturnAndRefundPolicy)}>Return and Refund Policy</div>}
          {props.metadata.ShippingPolicy && <div style={footerStyle.divAnchor} onClick={(e: any) => onLinkClick(e, 'Shipping Policy', props.metadata.ShippingPolicy)}>Shipping Policy</div>}
          {props.metadata.PaymentPolicy && <div style={footerStyle.divAnchor} onClick={(e: any) => onLinkClick(e, 'Payment Policy', props.metadata.PaymentPolicy)}>Payment Policy</div>}
          {props.metadata.PrivacyPolicy && <div style={footerStyle.divAnchor} onClick={(e: any) => onLinkClick(e, 'Privacy Policy', props.metadata.PrivacyPolicy)}>Privacy Policy</div>}
          {props.metadata.TermsAndConditions && <div style={footerStyle.divAnchor} onClick={(e: any) => onLinkClick(e, 'Terms And Conditions', props.metadata.TermsAndConditions)}>Terms and Conditions</div>}
        </div>}
        <div style={{ ...footerStyle.divMessage, flexDirection: props.width < 545 ? 'column' : 'row' }}>
          {showContactUs && (isContactEnabled || isEmailEnabled) && <div style={{ paddingLeft: 14, paddingBottom: 14, cursor: 'pointer', display: 'flex', flexDirection: 'row' }}>
            {props.metadata.CallEnabled && props.metadata.PhoneNo && <div onClick={(e: any) => onContactClick(e, 'call')}><PhoneOutlined style={{ padding: 8, fontSize: 20, color: props.commonStyles.footer.fontColor, }} /></div>}
            {props.metadata.WhatsappEnabled && props.metadata.PhoneNo && <div onClick={(e: any) => onContactClick(e, 'message')}><MessageOutlined style={{ padding: 8, fontSize: 20, color: props.commonStyles.footer.fontColor, }} /></div>}
            {props.metadata.MessageEnabled && props.metadata.PhoneNo && <div onClick={(e: any) => onContactClick(e, 'whatsapp')}><WhatsAppOutlined style={{ padding: 8, fontSize: 20, color: props.commonStyles.footer.fontColor, }} /></div>}
            {props.metadata.emailEnabled && props.metadata.Email && <div onClick={(e: any) => onContactClick(e, 'mail')}><MailOutlined style={{ padding: 8, fontSize: 20, color: props.commonStyles.footer.fontColor, }} /></div>}
          </div>}
          {(props.metadata.facebookLink || props.metadata.instaLink) && <div style={{ paddingLeft: 14, paddingBottom: 14, cursor: 'pointer', display: 'flex', flexDirection: 'row' }}>
            {props.metadata.facebookLink && <div onClick={() => onNewTabClick(props.metadata.facebookLink)}><FacebookOutlined style={{ padding: 8, fontSize: 20, color: props.commonStyles.footer.fontColor }} /></div>}
            {props.metadata.instaLink && <div onClick={() => onNewTabClick(props.metadata.instaLink)}><InstagramOutlined style={{ padding: 8, fontSize: 20, color: props.commonStyles.footer.fontColor }} /></div>}
          </div>}
          <p>{`Copyright © 2021, ${(props.company && props.company.name) || ''}. Proudly created with Naavo`}</p>
        </div>
      </div>
      <Modal visible={state.isVisible} onCancel={onDismiss} closable footer={[]} width={800} bodyStyle={{ backgroundColor: props.commonStyles.backgroundColor }}>
        <div style={{ display: 'flex', maxHeight: 500, flexDirection: 'column', justifyContent: 'space-around', padding: props.commonStyles.gridSize, fontFamily: props.commonStyles.fontfamily }}>
          <div style={{ color: props.commonStyles.fontColor.dark, fontSize: props.commonStyles.fontSize.large, padding: props.commonStyles.gridSize }}>{state.title}</div>
          <div style={{ maxHeight: 450, fontSize: props.commonStyles.fontSize.midlarge, color: props.commonStyles.fontColor.light, padding: props.commonStyles.gridSize, overflow: 'scroll' }}>{state.content}</div>
        </div>
      </Modal>
    </>
  );
});

function getFooterStyle(commonStyles: any) {
  return {
    divFooter: {
      backgroundColor: commonStyles.backgroundColor,
      padding: commonStyles.gridSize,
      width: '100%',
      borderWidth: 1,
      borderTopColor: commonStyles.fontColor.dark,
      borderTopStyle: 'solid'
    },
    divAnchor: {
      fontSize: commonStyles.fontSize.large,
      padding: commonStyles.gridSize * 0.5
    },
    divMessage: {
      fontFamily: commonStyles.fontfamily,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingBottom: commonStyles.gridSize,
    }
  };
}
