// @ts-nocheck
/*eslint eqeqeq: "off"*/
import { Button as Btn } from 'antd';
import React from 'react';

interface Properties {
  onClick?: (e: any) => void;
  isSmallScreen?: boolean;
  title: string;
  disabled?: boolean;
  commonStyles: any;
  size: string;
  type?: string;
  styles?: any;
}

export const Button = React.memo((props: Properties) => {  
  const isGhost = props.type == 'ghost';

  return (
    <Btn
        type={props.type || 'primary'}
        size={props.size}
        style={{
            marginTop: !props.isSmallScreen ? 0 : props.commonStyles.gridSize * 2,
            borderColor: props.commonStyles.button.backgroundColor,
            backgroundColor: isGhost ? 'transparent' : props.commonStyles.button.backgroundColor,
            cursor: 'pointer',
            fontFamily: props.commonStyles.fontfamily,
            marginBottom: props.commonStyles.gridSize,
            ...(props.styles || {})
        }}
        onClick={props.onClick}
        disabled={props.disabled}
        block
        >
        <p style={{ textAlign: 'center', color: props.commonStyles.button.color }}>{props.title}</p>
    </Btn>
  );
});
