import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'antd/dist/antd.css';
import './App.css';
import { Checkout } from './components/Checkout';
import { Home } from './components/Home';
import { BusinessCardInvoice } from './components/Redirect';

function App() {
  return (
    <Router>
        <Switch>
            <Route exact path="/" component={() => { 
                window.location.href = 'https://naavo.in'; 
                return null;
            }}/>
            <Route exact path="/preview/:title/:design/:color/:font/:theme/:alignment/:buttonType" component={Home} />
            <Route exact path="/sample/:sample/:design/:color/:font/:theme/:alignment/:buttonType" component={Home} />
            <Route exact path="/sample/:sample/checkout" component={Checkout} />
            <Route exact path="/preview/checkout" component={Checkout} />
            <Route exact path="/:id" component={Home} />
            <Route path="/:id/checkout" component={Checkout} />
            <Route exact path="/bcard/:businesscard" component={BusinessCardInvoice} />
            <Route exact path="/inv/:invoice" component={BusinessCardInvoice} />
        </Switch>
     </Router>
  );
}

export default App;
