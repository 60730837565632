// @ts-nocheck
import React from 'react';
import firebase from 'firebase';
import { Helmet } from "react-helmet";
import { List, Image, Layout, Modal, notification, Tag, Carousel, Card } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Footer } from './Footer';
import { Header } from './Header';
import { Redirect, useHistory, useParams } from 'react-router';
import { Actions, Company, Item, Metadata } from '../store';
import { ItemCard } from './Card';
import common from '../styles/common';
import { DemoItems, DemoMetadata, fallbackImage, getSampleItems } from '../data';
import { formatINR, getBackMessage, getButtonType, getCartNotificationMessage, getNoCartItemsMessage, isGridView, isListView, showCarousel, showCategoriesWithImages, showNewArrivalProducts, showTags, showTrendingProducts, toTitleCase } from '../utils';
import { getItems, getMetadata } from '../firebase/storage';
import { Button } from './Button';
import { firebaseConfig } from '../firebase/initialize';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { dayString, isDateAfter, isDateBefore, longDayString, toCurrentDate, toCurrentLocalDate, toDate, toLocalDate } from '../utils/date';

import Naavo1 from '../images/1.png';
import Naavo2 from '../images/2.png';
import Naavo3 from '../images/3.png';
import Naavo4 from '../images/4.png';
import Naavo5 from '../images/5.png';
import Naavo6 from '../images/6.png';

const { Content } = Layout;
const { CheckableTag } = Tag;

interface State {
  isSearchVisible: boolean;
  searchText: string | undefined;
  isVisible: boolean;
  item: Item;
  cart: { [key: string]: Item };
  items: Item[]; // total items
  metadata: Metadata;
  company: Company;
  searchItems: Item[]; // search items
  redirect: boolean;
  historyLength: number; // current router history count to avoid redirect push
  matches: any;
  loading: boolean;
  notFound: boolean;
  curItemCount: number; // for pagination
  filterItems: Item[]; // cateogery filter items,
  selectedCategory: string;
}

const initialState: State = {
  isSearchVisible: false,
  searchText: undefined,
  isVisible: false,
  item: undefined,
  cart: {},
  items: [],
  metadata: {},
  company: {},
  searchItems: [],
  redirect: false,
  historyLength: 0,
  matches: undefined,
  loading: false,
  notFound: false,
  curItemCount: 10,
  filterItems: [],
  selectedCategory: 'All',
};


export const Home = React.memo(() => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const store = useSelector(store => store).root;

  let { id, sample, design, font, color, title, alignment, theme, buttonType } = useParams();
  const isPreview = history && history.location && history.location.pathname ? history.location.pathname.indexOf('preview') > -1 : false;
  const isSample = history && history.location && history.location.pathname ? history.location.pathname.indexOf('sample') > -1 : false;
  const isBusinessCard = history && history.location && history.location.pathname ? history.location.pathname.indexOf('bcard') > -1 : false;
  const isInvoice = history && history.location && history.location.pathname ? history.location.pathname.indexOf('inv') > -1 : false;

  if (isBusinessCard || isInvoice) {
    window.location.href = 'https://naavo.in';
  }

  const mediaMatch = window.matchMedia('(max-width: 540px)');
  const [state, setState] = React.useState({ ...initialState, matches: mediaMatch.matches });

  const commonStyles = React.useMemo(() => {
    if (isPreview || isSample) {
      return common(design, color, font);
    } else {
      let design = store.metadata.design ? store.metadata.design : 'whiteMinimal';
      let color = store.metadata.color && typeof store.metadata.color === 'number' ? store.metadata.color : 0;
      let font = store.metadata.font && typeof store.metadata.font === 'number' ? store.metadata.font : 0;
      return common(design, color, font);
    }
  }, [isPreview, isSample, store, color, design, font]);
  const storeDetails = React.useMemo(() => {
    return {
      metadata: isPreview || isSample ? { ...DemoMetadata } : store.metadata,
      company: isPreview ? { id: 'dummy', name: title || 'Demo' } : (isSample ? { id: 'sample', name: `${sample} Store` } : store.company),
      theme: (isPreview || isSample ? theme : store.metadata.theme) || 0,
      alignment: (isPreview || isSample ? alignment : store.metadata.alignment) || 0,
      buttonType: (isPreview || isSample ? buttonType : store.metadata.buttonType) || 0,
    };
  }, [store, isPreview, isSample, sample, theme, title, alignment, buttonType]);

  const storeMetadata: Metadata = storeDetails.metadata;
  const storeCompany: Company = storeDetails.company;
  const storeTheme = storeDetails.theme;
  const storeAlignment = storeDetails.alignment;
  const storeButtonType = storeDetails.buttonType;

  const listItems = React.useMemo(() => {
    if (isPreview) {
      return DemoItems;
    }

    if (isSample) {
      return getSampleItems(sample);
    }

    let items = [];
    if (store.items && typeof store.items[Symbol.iterator] !== 'function') {
      return items;
    }

    for (const item of store.items) {
      let filterItem = false;
      if ((storeMetadata.filterSelection === 'all' && storeMetadata.selectedItems[item.id] !== undefined)
        || (storeMetadata.filterSelection === undefined && storeMetadata.selectedItems[item.id] === undefined)) {
        filterItem = true;
      }

      if (!filterItem) {
        items.push(item);
      }
    }

    return items;
  }, [store.items, isPreview, isSample, sample, storeMetadata]);
  const trending = React.useMemo(() => {
    if (!storeMetadata.trendingItems) {
      return [];
    }

    let listItems = isPreview ? DemoItems : store.items;

    if (isSample) {
      listItems = getSampleItems(sample);
    }

    let items = [];
    if (listItems && typeof listItems[Symbol.iterator] !== 'function') {
      return items;
    }

    for (const item of listItems) {
      if (storeMetadata.trendingItems[item.id]) {
        items.push(item);
      }
    }

    return items;
  }, [store.items, storeMetadata, isSample, sample, isPreview]);
  const newArrivals = React.useMemo(() => {
    if (!storeMetadata.newArrivalsItems) {
      return [];
    }

    let listItems = isPreview ? DemoItems : store.items;

    if (isSample) {
      listItems = getSampleItems(sample);
    }

    let items = [];
    if (listItems && typeof listItems[Symbol.iterator] !== 'function') {
      return items;
    }

    for (const item of listItems) {
      if (storeMetadata.newArrivalsItems[item.id]) {
        items.push(item);
      }
    }

    return items;
  }, [store.items, storeMetadata, isSample, sample, isPreview]);

  const onItemCardClick = React.useCallback((e, item) => setState((state: any) => {
    return {
      ...state,
      isVisible: true,
      item: item
    };
  }), [setState]);
  const onAddToCardClick = React.useCallback((e, item) => {
    const obj = state.cart || {};
    obj[item.id] = { ...item, orderQuantity: 1 };

    setState((state: any) => {
      return {
        ...state,
        cart: obj
      };
    });

    notification.open({
      message: <div style={{ color: commonStyles.fontColor.dark }}>{getCartNotificationMessage(storeButtonType, item.name)}</div>,
      style: { backgroundColor: commonStyles.backgroundColor, color: commonStyles.fontColor.dark, fontFamily: commonStyles.fontfamily, fontSize: commonStyles.fontSize.medium }
    });
  }, [setState, state.cart, storeButtonType, commonStyles]);

  const onDismiss = React.useCallback(() => setState((state: any) => {
    return {
      ...state,
      isVisible: false,
    };
  }), [setState]);
  const onRenderItem = React.useCallback((item: Item) => {
    return (
      <List.Item>
        <ItemCard
          windowWidth={width}
          item={item}
          onCardClick={(e) => onItemCardClick(e, item)}
          onAddToCardClick={(e) => onAddToCardClick(e, item)}
          commonStyles={commonStyles}
          alignment={storeAlignment}
          buttonType={storeButtonType}
        />
      </List.Item>
    );
  }, [width, storeAlignment, storeButtonType, commonStyles, onItemCardClick, onAddToCardClick]);
  const onSearch = React.useCallback((e) => setState((state: any) => {
    return {
      ...state,
      searchText: e.target.value
    };
  }), [setState]);
  const onShowSearch = React.useCallback(() => setState((state: any) => {
    return {
      ...state,
      isSearchVisible: !state.isSearchVisible
    }
  }), [setState]);
  const onShowCart = React.useCallback(() => {
    if (Object.keys(state.cart).length === 0) {
      notification.open({
        message: <div style={{ color: commonStyles.fontColor.dark }}>{getNoCartItemsMessage(storeButtonType)}</div>,
        style: { backgroundColor: commonStyles.backgroundColor, color: commonStyles.fontColor.dark, fontFamily: commonStyles.fontfamily, fontSize: commonStyles.fontSize.medium }
      });
      return;
    }

    setState((state: any) => {
      return {
        ...state,
        redirect: true,
        historyLength: history.length
      }
    })
  }, [state.cart, commonStyles, storeButtonType, setState, history.length]);
  const onBackButtonEvent = React.useCallback((e) => {
    e.preventDefault();
    if (Object.keys(state.cart).length > 0) {
      if (window.confirm(getBackMessage(storeButtonType))) {
        history.push("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
      }
    }
  }, [state.cart, history, storeButtonType]);
  const onMediaHandler = React.useCallback((e) => setState((state: any) => {
    return {
      ...state,
      matches: e.matches
    }
  }), [setState]);
  const isStoreOpen = React.useMemo(() => {
    if (isPreview || !storeMetadata.timings) {
      return {
        from: '',
        to: '',
        open: true
      };
    }

    const localDate = toLocalDate();
    const day = dayString[localDate.getDay()];
    if (storeMetadata.timings[day]) {
      if (storeMetadata.timings[day] == 'closed') {
        return {
          from: '',
          to: '',
          open: false,
          day
        };
      }

      const from = toCurrentDate(toDate(storeMetadata.timings[day]['from']));
      const to = toCurrentDate(toDate(storeMetadata.timings[day]['to']));

      const currentDate = toCurrentLocalDate();
      return {
        from: from.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        to: to.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        open: isDateAfter(from, currentDate) && isDateBefore(currentDate, to),
        day
      };
    }

    return {
      from: '',
      to: '',
      open: true,
      day
    };
  }, [storeMetadata, isPreview]);
  const onLoadMore = React.useCallback(() => setState((state: any) => {
    return {
      ...state,
      curItemCount: state.curItemCount + 10,
    };
  }), [setState]);
  const categories = React.useMemo(() => {
    const items = listItems.filter(o => o.category !== undefined && o.category !== null && o.category.trim().length > 0).map(o => o.category).filter((v, i, a) => a.indexOf(v) === i);
    return ['All'].concat(items);
  }, [listItems]);
  const onHandleChange = React.useCallback((category: string, checked: boolean) => {
    let items = [];
    if (category == 'All') {
      items = listItems;
    } else {
      for (const item of listItems) {
        if (item.category && item.category === category) {
          items.push(item);
        }
      }
    }

    setState((state: State) => {
      return {
        ...state,
        filterItems: items,
        selectedCategory: category
      };
    });
  }, [listItems, setState]);
  const selectedItem = React.useMemo(() => {
    let imageUri = ''; let description = '';
    if (state.item && state.item.metadata) {
      const metadata = JSON.parse(state.item.metadata);
      if (metadata.downloadImageUri) {
        imageUri = metadata.downloadImageUri;
      }
      if (metadata.description) {
        description = metadata.description;
      }
    }

    return {
      imageUri,
      description
    };
  }, [state.item]);
  const listViewGrid = React.useMemo(() => listViewGridConfiguration(commonStyles.gridSize, storeAlignment), [commonStyles.gridSize, storeAlignment]);
  const showElementsView = React.useMemo(() => showElements(storeTheme), [storeTheme]);
  const carouselAfterChange = React.useCallback((currentSlide: number) => {
    if (currentSlide == 0) {
      document.body.style.backgroundColor = '#a8dba8';
    } else if (currentSlide == 1) {
      document.body.style.backgroundColor = '#e0e3da';
    } else if (currentSlide == 2) {
      document.body.style.backgroundColor = '#41d3bd';
    } else if (currentSlide == 3) {
      document.body.style.backgroundColor = '#c5e99b';
    } else if (currentSlide == 4) {
      document.body.style.backgroundColor = '#e3e3bd';
    } else if (currentSlide == 5) {
      document.body.style.backgroundColor = '#e3e36a';
    }
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => handleSearch(commonStyles, setState, listItems, state.searchText), 1000);

    return () => clearTimeout(timer);
  }, [setState, listItems, state.searchText, commonStyles]);

  React.useEffect(() => {
    if (Object.keys(state.cart).length > 0) {
      window.addEventListener('beforeunload', (event: any) => { event.preventDefault(); event.returnValue = ''; });
      window.addEventListener('unload', () => { });
      return () => {
        window.removeEventListener('beforeunload', (event: any) => { event.preventDefault(); event.returnValue = ''; });
        window.removeEventListener('unload', () => { });
      }
    }
  }, [state.cart]);

  React.useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    mediaMatch.addListener(onMediaHandler);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      mediaMatch.removeListener(onMediaHandler);
    };
  }, [mediaMatch, onBackButtonEvent, onMediaHandler]);

  React.useEffect(() => {
    if (isPreview || isSample || state.notFound || (listItems.length > 0 && storeCompany.id === id)) {
      return;
    }

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      firebase.analytics();
    }

    loadData(setState, id, dispatch);
  }, [setState, dispatch, isPreview, isSample, storeCompany.id, state.notFound, listItems.length, id]);

  if (state.redirect) {
    return (
      <Redirect
        push={state.historyLength === history.length}
        to={{
          pathname: isPreview ? `/preview/checkout` : (isSample ? `/sample/${sample}/checkout` : `/${id}/checkout`), state: {
            data: {
              items: listItems,
              cart: state.cart,
              metadata: storeMetadata,
              company: storeCompany,
              commonStyles: commonStyles,
              theme: storeTheme,
              alignment: storeAlignment,
              buttonType: storeButtonType
            }
          }
        }}
      />
    );
  }

  if (state.loading) {
    document.title = 'Loading Online Store...';
    document.body.style.backgroundColor = '#a8dba8';
    return (
      <>
        <LoadingOutlined style={{ display: 'flex', color: '#1d3557', fontSize: 30, flex: 1, marginTop: '8%', justifyContent: 'center' }} />
        <div style={{ padding: 20, textAlign: 'center', fontFamily: commonStyles.fontfamily, fontSize: commonStyles.fontSize.extraLarge }}>{'Loading your store'}</div>
        <div style={{ padding: 20, textAlign: 'center', fontFamily: commonStyles.fontfamily, fontSize: commonStyles.fontSize.extraLarge }}>{'This store is proudly created with Naavo. A little bit about Naavo'}</div>
        <Carousel style={{ borderWidth: 1, borderColor: commonStyles.fontColor.dark, width: 400, margin: 'auto' }}
          autoplay={true}
          autoplaySpeed={1000}
          dots={false}
          afterChange={carouselAfterChange}
        >
          <Image
            src={Naavo1}
            fallback={fallbackImage}
            preview={false}
          />
          <Image
            src={Naavo2}
            fallback={fallbackImage}
            preview={false}
          />
          <Image
            src={Naavo3}
            fallback={fallbackImage}
            preview={false}
          />
          <Image
            src={Naavo4}
            fallback={fallbackImage}
            preview={false}
          />
          <Image
            src={Naavo5}
            fallback={fallbackImage}
            preview={false}
          />
          <Image
            src={Naavo6}
            fallback={fallbackImage}
            preview={false}
          />
        </Carousel>
      </>
    );
  }

  let imageUri = selectedItem.imageUri;
  let description = selectedItem.description;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Proudly created with Naavo" />
        <title>{`${storeCompany.name} - Online Store`}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Layout>
        <Header
          isSearchVisible={state.isSearchVisible}
          cartCount={Object.keys(state.cart).length}
          onShowSearch={onShowSearch}
          onShowCart={onShowCart}
          onSearch={onSearch}
          company={state.notFound || !storeMetadata.isLive ? undefined : storeCompany}
          hideCartIcon={state.notFound || listItems.length == 0 || !storeMetadata.isLive || state.loading}
          hideSearchIcon={true}
          metadata={storeMetadata}
          commonStyles={commonStyles}
        />
        <Content className="site-layout" style={{ padding: '0 5px', marginTop: 80, minHeight: '80vh', background: commonStyles.backgroundColor }}>
          {!state.notFound && storeMetadata.isLive && !isStoreOpen.open
            ? <div style={{ textAlign: 'center', fontFamily: commonStyles.fontfamily, fontSize: commonStyles.fontSize.midlarge, color: commonStyles.fontColor.dark, padding: commonStyles.gridSize }}>
              {isStoreOpen.from != ''
                ? `We are open from ${isStoreOpen.from} to ${isStoreOpen.to} on ${longDayString[isStoreOpen.day]}.`
                : `We are closed on ${longDayString[isStoreOpen.day]}.`}
            </div>
            : null
          }
          {(state.notFound || listItems.length == 0 || !storeMetadata.isLive) && !isPreview
            ? null
            : <>
              {showElementsView.showCarou ?
                <Carousel style={{
                  padding: 10,
                  borderWidth: 1,
                  borderColor: commonStyles.fontColor.dark,
                  width: width - 10,
                }} autoplay dotPosition={'bottom'} dots={true}>
                  <div>
                    <Image
                      height={width < 575 ? 240 : 400}
                      width={width - 10}
                      src={''}
                      style={{ padding: commonStyles.gridSize, paddingBottom: 0 }}
                      fallback={fallbackImage}
                      preview={false}
                    />
                  </div>
                  <div>
                    <Image
                      height={width < 575 ? 240 : 400}
                      width={width - 10}
                      src={''}
                      style={{ padding: commonStyles.gridSize, paddingBottom: 0 }}
                      fallback={fallbackImage}
                      preview={false}
                    />
                  </div>
                </Carousel>
                : null}
              {showElementsView.showTrending && trending.length > 0 ? <div
                style={{
                  padding: commonStyles.gridSize,
                  fontWeight: 'bold',
                  fontFamily: commonStyles.fontfamily,
                  color: commonStyles.fontColor.dark,
                  fontSize: commonStyles.fontSize.large
                }}>
                {'Trending'}
              </div> : null}
              {showElementsView.showTrending && trending.length > 0 ?
                <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginBottom: 16, overflow: 'scroll' }}>
                  {trending.map(item => (
                    <ItemCard
                      windowWidth={width}
                      item={item}
                      onCardClick={(e) => onItemCardClick(e, item)}
                      onAddToCardClick={(e) => onAddToCardClick(e, item)}
                      commonStyles={commonStyles}
                      alignment={storeAlignment}
                      cardstyle={{ marginLeft: 8 }}
                      buttonType={storeButtonType}
                    />
                  ))}
                </div>
                : null}
              {showElementsView.showNewArr && newArrivals.length > 0 ? <div
                style={{
                  padding: commonStyles.gridSize,
                  fontWeight: 'bold',
                  fontFamily: commonStyles.fontfamily,
                  color: commonStyles.fontColor.dark,
                  fontSize: commonStyles.fontSize.large
                }}>
                {'New Arrivals'}
              </div> : null}
              {showElementsView.showNewArr && newArrivals.length > 0 ?
                <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginBottom: 16, overflow: 'scroll' }}>
                  {newArrivals.map(item => (
                    <ItemCard
                      windowWidth={width}
                      item={item}
                      onCardClick={(e) => onItemCardClick(e, item)}
                      onAddToCardClick={(e) => onAddToCardClick(e, item)}
                      commonStyles={commonStyles}
                      alignment={storeAlignment}
                      cardstyle={{ marginLeft: 8 }}
                      buttonType={storeButtonType}
                    />
                  ))}
                </div>
                : null}
              {(showElementsView.showCategories || showElementsView.showCategoriesWithIm)
                ? <div
                  style={{
                    padding: commonStyles.gridSize,
                    fontWeight: 'bold',
                    fontFamily: commonStyles.fontfamily,
                    color: commonStyles.fontColor.dark,
                    fontSize: commonStyles.fontSize.large
                  }}>
                  {'Browse Categories'}
                </div>
                : null}
              {showElementsView.showCategories
                ? categories.map(o => (
                  <CheckableTag
                    key={o}
                    checked={state.selectedCategory === o}
                    onChange={state.selectedCategory === o ? undefined : (checked) => onHandleChange(o, checked)}
                    style={{
                      padding: 6,
                      margin: 4,
                      marginLeft: 8,
                      fontFamily: commonStyles.fontfamily,
                      color: state.selectedCategory === o ? commonStyles.backgroundColor : commonStyles.fontColor.dark,
                      backgroundColor: state.selectedCategory === o ? commonStyles.fontColor.light : commonStyles.backgroundColor,
                      borderWidth: 1,
                      borderColor: commonStyles.fontColor.dark
                    }}
                  >
                    {o}
                  </CheckableTag>
                ))
                : null}
              {showElementsView.showCategoriesWithIm
                ? <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap', marginBottom: 16 }}>
                  {categories.map(o => (
                    <Card
                      hoverable
                      bordered
                      style={{ width: 80, margin: 4, height: 90, background: 'transparent', borderColor: 'transparent' }}
                      bodyStyle={{ width: 80, margin: 4, height: 90, padding: 0, background: 'transparent' }}
                    >
                      <Image
                        height={60}
                        width={60}
                        src={''}
                        style={{ padding: 0, marginLeft: 4 }}
                        fallback={fallbackImage}
                        preview={false}
                        onClick={undefined}
                      />
                      <div style={{ width: 60, fontWeight: 'bold', textAlign: 'center', fontSize: commonStyles.fontSize.small, color: commonStyles.fontColor.dark }}>
                        {o}
                      </div>
                    </Card>
                  ))}
                </div>
                : null}
              {showElementsView.showCategories || showElementsView.showCategoriesWithIm
                ? <div
                  style={{
                    padding: commonStyles.gridSize,
                    paddingTop: commonStyles.gridSize * 2,
                    fontWeight: 'bold',
                    fontFamily: commonStyles.fontfamily,
                    color: commonStyles.fontColor.dark,
                    fontSize: commonStyles.fontSize.large
                  }}>
                  {`${state.selectedCategory}`}
                </div>
                : null}
              <List
                grid={listViewGrid}
                style={{ padding: commonStyles.gridSize }}
                dataSource={state.filterItems && state.filterItems.length > 0 ? state.filterItems : listItems.slice(0, state.curItemCount)}
                renderItem={onRenderItem}
                loading={state.loading}
                loadMore={!state.loading && (state.curItemCount < listItems.length)
                  ? (
                    <Button
                      type={storeAlignment == 1 ? 'ghost' : 'primary'}
                      isSmallScreen={width < 575}
                      onClick={onLoadMore}
                      title={'Load more'}
                      commonStyles={commonStyles}
                      size={width < 575 ? 'small' : 'middle'}
                    />
                  )
                  : null}
              />
            </>}
          {(state.notFound || listItems.length == 0 || !storeMetadata.isLive) && !isPreview
            ? <div
              style={{
                fontFamily: commonStyles.fontfamily,
                color: commonStyles.fontColor.dark,
                textAlign: 'center',
                marginTop: '10%',
                fontSize: commonStyles.fontSize.extraextraLarge
              }}>
              {(listItems.length == 0)
                ? 'The online store is still in creation process. Please, check back later.'
                : !storeMetadata.isLive
                  ? 'The store address is no longer active. Please, verify the store address and try again'
                  : 'The store address is not valid. Please, verify the store address and try again'}
            </div>
            : null
          }
        </Content>
        {(state.notFound || listItems.length == 0 || !storeMetadata.isLive) && !isPreview
          ? null
          : <Footer theme={storeTheme} width={width} metadata={storeMetadata} company={state.notFound || !storeMetadata.isLive ? undefined : storeCompany} commonStyles={commonStyles} />
        }
      </Layout>
      <Modal
        visible={state.isVisible}
        onCancel={onDismiss}
        closable
        footer={<></>}
        width={800}
        bodyStyle={{ backgroundColor: commonStyles.backgroundColor }}
      >
        <div style={{ backgroundColor: commonStyles.backgroundColor, maxHeight: !state.matches ? 400 : 700, display: 'flex', flexDirection: !state.matches ? 'row' : 'column', padding: commonStyles.gridSize, fontFamily: commonStyles.fontfamily }}>
          <Image
            src={imageUri}
            style={{ paddingBottom: commonStyles.gridSize, maxWidth: 400, maxHeight: 400 }}
            fallback={fallbackImage}
            preview={false}
          />
          <div style={{ maxWidth: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: !state.matches ? commonStyles.gridSize * 1.5 : commonStyles.gridSize }}>
            <div>
              {state.item ? <div style={{ color: commonStyles.fontColor.dark, fontSize: commonStyles.fontSize.extraLarge, paddingLeft: !state.matches ? commonStyles.gridSize : 0 }}>{toTitleCase(state.item.name)}</div> : null}
              <div style={{ fontFamily: commonStyles.glyphFamily, fontSize: commonStyles.fontSize.large, color: commonStyles.fontColor.light, paddingLeft: !state.matches ? commonStyles.gridSize : 0 }}>{formatINR((state.item && state.item.price) || 0)}</div>
              {description !== '' ? <div style={{ maxHeight: 250, overflow: 'scroll', fontFamily: commonStyles.glyphFamily, fontSize: commonStyles.fontSize.medium, color: commonStyles.fontColor.light, paddingLeft: !state.matches ? commonStyles.gridSize : 0 }}>{description}</div> : null}
            </div>
            <Button
              commonStyles={commonStyles}
              type={'primary'}
              isSmallScreen={width < 575}
              onClick={state.item && state.item.quantity > 0 ? (e) => onAddToCardClick(e, state.item) : undefined}
              title={state.item && state.item.quantity > 0 ? getButtonType(storeButtonType) : 'Out of Stock'}
              size={'middle'}
            />
          </div>
        </div>
      </Modal>
    </>
  );
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function handleSearch(commonStyles: any, setState: any, items: Item[], searchValue: string) {
  if (searchValue === undefined || searchValue === null || searchValue === '') {
    setState((state: any) => {
      return {
        ...state,
        searchItems: []
      };
    });
    //dispatch({ type: Actions.SEARCH, payload: undefined });
  } else {
    const searchItems = [];
    for (const item of items) {
      if (item.name.toLowerCase().startsWith(searchValue.toLowerCase())) {
        searchItems.push(item);
      }
    }

    if (searchItems.length === 0) {
      notification.open({
        message: <div style={{ color: commonStyles.fontColor.dark }}>{`No items were found`}</div>,
        style: { backgroundColor: commonStyles.backgroundColor, color: commonStyles.fontColor.dark, fontFamily: commonStyles.fontfamily, fontSize: commonStyles.fontSize.medium }
      });
      return;
    }

    setState((state: any) => {
      return {
        ...state,
        searchItems
      };
    });
    //dispatch({ type: Actions.SEARCH, payload: searchItems });
  }
}

async function loadData(setState: any, store: string, dispatch: Dispatch<any>) {
  setState((state: State) => {
    return {
      ...state,
      loading: true,
      notFound: false
    };
  });

  const fetchMetadata = getMetadata(store);
  const fetchItems = getItems(store);

  const fetchedData: [any, any] = await Promise.all([fetchMetadata, fetchItems]);
  if (fetchedData[0] == null || fetchedData[1] == null) { // not found
    dispatch({
      type: Actions.LOAD,
      payload: {
        items: [],
        metadata: {},
        company: {}
      }
    });

    setState((state: State) => {
      return {
        ...state,
        loading: false,
        notFound: true
      };
    });
  } else {
    const { companyDetails, ...metadata } = fetchedData[0];
    dispatch({
      type: Actions.LOAD,
      payload: {
        items: fetchedData[1],
        metadata: metadata,
        company: {
          id: store,
          name: metadata.title,
          address: companyDetails && companyDetails.address,
          phone: companyDetails && companyDetails.phone,
          state: companyDetails && companyDetails.state,
          industry: companyDetails && companyDetails.industry,
          country: companyDetails && companyDetails.country,
        }
      }
    });

    setState((state: any) => {
      return {
        ...state,
        loading: false,
        notFound: false
      };
    });
  }
}

function listViewGridConfiguration(gridSize, storeAlignment) {
  const xsColumnCount = isGridView(storeAlignment) ? 2 : 1;
  const smColumnCount = isGridView(storeAlignment) ? 3 : (isListView(storeAlignment) ? 1 : 2);
  const mdColumnCount = isListView(storeAlignment) ? 2 : 4;
  const lgColumnCount = isListView(storeAlignment) ? 3 : 5;
  const xlColumnCount = isListView(storeAlignment) ? 4 : 6;
  const xxlColumnCount = isListView(storeAlignment) ? 5 : 7;

  return {
    gutter: gridSize,
    xs: xsColumnCount,
    sm: smColumnCount,
    md: mdColumnCount,
    lg: lgColumnCount,
    xl: xlColumnCount,
    xxl: xxlColumnCount
  };
}

function showElements(storeTheme: number) {
  const showCategories = showTags(storeTheme);
  const showCarou = showCarousel(storeTheme);
  const showCategoriesWithIm = showCategoriesWithImages(storeTheme);
  const showTrending = showTrendingProducts(storeTheme);
  const showNewArr = showNewArrivalProducts(storeTheme);

  return {
    showCategories,
    showCarou,
    showCategoriesWithIm,
    showTrending,
    showNewArr
  };
}