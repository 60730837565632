// @ts-nocheck
import { ShoppingCartOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Badge } from 'antd';
import React, { CSSProperties } from 'react';
import { Company, Metadata } from '../store';
import { toTitleCase } from '../utils';

interface Properties {
  isSearchVisible: boolean;
  cartCount?: number;
  onSearch?: (e: any) => void;
  onShowSearch?: (e: any) => void;
  onShowCart?: (e: any) => void;
  company: Company;
  metadata: Metadata;
  hideSearchIcon?: boolean;
  hideCartIcon?: boolean;
  onTitleClick?: () => {};
  commonStyles: any;
}

export const Header = React.memo((props: Properties) => {
  const headerStyle = React.useMemo(() => getHeaderStyle(props.commonStyles), [props.commonStyles]);
  
  return (
    <div className="div-header" style={{ ...headerStyle.divHeader }}>
      <div style={{ ...headerStyle.divTitle, cursor: 'pointer' }} onClick={props.onTitleClick}>{toTitleCase(props.company && props.company.name) || ''}</div>
      <div style={headerStyle.divIcon}>
        {props.isSearchVisible && <Input placeholder="Search Product" style={headerStyle.divInput} onChange={props.onSearch} />}
        {!props.hideSearchIcon && <div onClick={props.onShowSearch}>
          <SearchOutlined style={headerStyle.divIconField} />
        </div>}
        {!props.hideCartIcon && <div onClick={props.onShowCart}>
          <Badge count={props.cartCount || 0} offset={[2, 5]} style={headerStyle.divDot}>
            <ShoppingCartOutlined style={headerStyle.divIconField} />
          </Badge>
        </div>}
      </div>
    </div>
  );
});

function getHeaderStyle(commonStyles: any): { [key: string]: CSSProperties } {
  return {
    divHeader: {
      backgroundColor: commonStyles.backgroundColor,
      padding: commonStyles.gridSize * 3,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'fixed',
      zIndex: 1,
      width: '100%',
      height: '80px',
      borderWidth: 1,
      borderBottomColor: commonStyles.fontColor.dark,
      borderBottomStyle: 'solid'
    },
    divIcon: {
      display: 'flex',
      flexDirection: 'row',
      paddingRight: commonStyles.gridSize * 3,
    },
    divInput: {
      fontFamily: commonStyles.fontfamily,
      fontSize: commonStyles.fontSize.midlarge,
      color: commonStyles.fontColor.dark,
      padding: commonStyles.gridSize,
      width: 200
    },
    divDot: {
      fontFamily: commonStyles.fontfamily,
      fontSize: commonStyles.fontSize.midmedium,
      color: commonStyles.fontColor.dark,
      backgroundColor: commonStyles.backgroundColor
    },
    divTitle: {
      fontFamily: commonStyles.fontfamily,
      fontSize: commonStyles.fontSize.large,
      color: commonStyles.fontColor.dark,
      fontWeight: 'bold',
      marginTop: '10px'
    },
    divIconField: {
      padding: commonStyles.gridSize,
      fontSize: '150%',
      color: commonStyles.fontColor.dark
    },
    divPopover: {
      fontFamily: commonStyles.fontfamily,
      fontSize: commonStyles.fontSize.extraLarge,
      color: commonStyles.fontColor.dark,
      fontWeight: 'bold',
    }
  };
}

