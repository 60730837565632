// @ts-nocheck
import validator from 'validator';
import { toTrimValue } from './string';

interface ValidatorRegexType {
    [key: string]: any;
}

interface ValidationResult {
    pass: boolean;
    message: string;
}

export const ValidatorRegex: ValidatorRegexType = {
    AlphaNumericRegex: /^[\w\-\s]{2,120}$/,
    AlphaRegex: /^[\w\-\s]{2,120}$/,
    PhoneRegex: /^[\d]{10}$/,
    ZipCodeRegex: /^[\d]{6}$/,
    AddressRegex: /^[a-zA-Z\d\-_.,/\s]+$/,
    QuantityAndPriceRegex: /^[\d]{1,20}([.][\d]{1,2})?$/,
};

export function ValidateField(fieldLabel: string,
                              fieldValue: string,
                              fieldRegex?: string,
                              isRequired?: boolean): ValidationResult {     
    const validationResult: any = {};
    const regex = fieldRegex && ValidatorRegex[fieldRegex];
    let value = toTrimValue(fieldValue);
    if (isRequired && (!value || value.length === 0)) {
        validationResult.pass = false;
        validationResult.message = getMessage(fieldLabel, 1);
    } else if (value && value.length > 0 && regex && !regex.test(value)) {
        validationResult.pass = false;
        validationResult.message = getMessage(fieldLabel, 2);
    } else if (value && value.length > 0 && fieldRegex && fieldRegex === 'email' && !validator.isEmail(value)) {
        validationResult.pass = false;
        validationResult.message = getMessage(fieldLabel, 2);
    } else {
        validationResult.pass = true;
    }
    return validationResult;
}

function getMessage(fieldLabel: string, validationType: number) {   
   if (validationType === 1) {
       return 'Required';
   }

   if (validationType === 2) {
       return `Provide a valid ${fieldLabel}`;
   }

   return 'Input is too long';
}
