export function showCategoryView(theme: number) {
    return theme >= 1 && theme <= 5;
}
  
export function isMagazineView(alignment: number) {
    return alignment == 0;
}
  
export function isGridView(alignment: number) {
    return alignment == 1;
}
  
export function isListView(alignment: number) {
    return alignment == 2;
}

export function showTags(theme: number) {
    return theme >= 1 && theme <= 2;
}

export function showCarousel(theme: number) {
    return theme > 5;
}

export function showCategoriesWithImages(theme: number) {
    return theme >= 3;
}

export function showTrendingProducts(theme: number) {
    return theme >= 2;
}

export function showNewArrivalProducts(theme: number) {
    return theme >= 2;
}

export function getButtonType(buttonType: number) {
    if (buttonType == 2) {
        return 'Add to Order';
    } else if (buttonType == 1) {
        return 'Add to List';
    } 

    return 'Add to Cart';
}

export function getBackMessage(buttonType: number) {
    if (buttonType == 2) {
        return 'You sure you want to go back? You will lose your order items and will need to start again';
    } else if (buttonType == 1) {
        return 'You sure you want to go back? You will lose your list items and will need to start again';
    } 

    return 'You sure you want to go back? You will lose your cart items and will need to start again';
}

export function getNoCartItemsMessage(buttonType: number) {
    if (buttonType == 2) {
        return 'Currently, you have no items in your order';
    } else if (buttonType == 1) {
        return 'Currently, you have no items in your list';
    } 

    return 'Currently, you have no items in your cart';
}

export function getCartNotificationMessage(buttonType: number, itemName: string) {
    if (buttonType == 2) {
        return `Product - ${itemName} added to the order`;
    } else if (buttonType == 1) {
        return `Product - ${itemName} added to the list`;
    } 

    return `Product - ${itemName} added to the cart`;
}

export function getCheckoutTitle(buttonType: number) {
    if (buttonType == 2) {
        return `Your Order`;
    } else if (buttonType == 1) {
        return `Your List`;
    } 

    return `Your Cart`;
}