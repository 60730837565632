// @ts-nocheck
import { createStore, combineReducers, applyMiddleware } from 'redux';
import update from 'immutability-helper';
import { persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

export enum Actions {
    ITEMS = 'ITEMS',
    METADATA = 'METADATA',
    ADDTOCART = 'ADDTOCART',
    SEARCH = 'SEARCH',
    COMPANY = 'COMPANY',
    LOAD = 'LOAD'
};

export interface Company {
    id?: string;
    name: string;
    address?: string;
    phone?: string;
    state?: string;
    industry?: string;
    country?: string;
}

export interface Metadata {
   AboutUs?: string;
   ReturnAndRefundPolicy?: string;
   ShippingPolicy?: string;
   PaymentPolicy?: String;
   PrivacyPolicy?: string;
   TermsAndConditions?: string;
   codEnabled?: boolean;
   deliveryEnabled?: boolean;
   pickupEnabled?: boolean;
   CallEnabled?: boolean;
   MessageEnabled?: boolean;
   WhatsappEnabled?: boolean;
   emailEnabled?: boolean;
   MinimumCheckout?: string;
   isLive?: boolean;
   PickupAddress?: string;
   PickupInstruction?: string;
   DeliveryInstruction?: string;
   CODInstruction?: string;
   DeliveryCharges?: string;
   Email?: string;
   PhoneNo?: string;
   filterSelection?: string;
   selectedItems?: any;
   title?: string;
   theme?: number;
   gstEnabled?: boolean;
   Tax?: string;
   design?: string;
   font?: number;
   color?: number;
   newArrivalsItems?: { [key: string]: boolean; };
   trendingItems?: { [key: string]: boolean; };
   timings?: { [key: string]: any; };
   facebookLink?: string;
   instaLink?: string;
}

export interface Item {
    id?: string;
    companyId?: string;
    barcode?: string;
    barcodeType?: string;
    code?: string;
    name: string;
    quantity: number;
    unit: string;
    price: number;
    cgst?: number;
    sgst?: number;
    igst?: number;
    tax?: number;
    total?: number;
    metadata?: string;
    isActive?: boolean;
    orderQuantity?: number;
}

export interface ReduxState {
    company: Company;
    theme: string;
    items: Item[];
    metadata: Metadata;
    cart: { [key: string]: Item };
    searchItems: Item[] | undefined;
}

export interface ReduxAction {
    type: Actions;
    payload: string | boolean | Company | Item[] | Metadata | { [key: string]: Item } | { [key: string]: any; } | undefined;
}

// const persistConfig = {
//     key: 'root',
//     version: 1,
//     storage: storage,
// };

const initialState = {
    company: {
        id: undefined,
        name: '',
    },
    theme: 'light',
    loading: false,
    error: undefined,
    success: undefined,
    loggedIn: undefined,
    items: [],
    metadata: {},
    cart: {},
    searchItems: undefined
};

const itemsReducer = (state: ReduxState, payload: any[]) => update(state, {
    items: { $set: payload }
});

const metadataReducer = (state: ReduxState, payload: any) => update(state, {
    metadata: { $set: payload },
});

const companyReducer = (state: ReduxState, payload: any) => update(state, {
    company: { $set: payload },
});

const addToCardReducer = (state: ReduxState, payload: any) => update(state, {
    cart: { $set: payload },
});

const searchReducer = (state: ReduxState, payload: any) => update(state, {
    searchItems: { $set: payload },
});

const loadReducer = (state: ReduxState, payload: any) => update(state, {
    items: { $set: payload.items },
    metadata: { $set: payload.metadata },
    company: { $set: payload.company },
});

const root = (state = initialState, actionreducer: ReduxAction): ReduxState => {
    switch (actionreducer.type) {
        case Actions.ITEMS:
            return itemsReducer(state, actionreducer.payload as any);
        case Actions.METADATA:
            return metadataReducer(state, actionreducer.payload as any);
        case Actions.ADDTOCART:
            return addToCardReducer(state, actionreducer.payload as any);
        case Actions.SEARCH:
            return searchReducer(state, actionreducer.payload as any);
        case Actions.COMPANY:
            return companyReducer(state, actionreducer.payload as any);
        case Actions.LOAD:
            return loadReducer(state, actionreducer.payload as any);
        default:
            return state;
    }
};

export const reducers = combineReducers({ root });
// const persistedReducer = persistReducer(persistConfig, reducers);
const reduxStore = createStore(reducers, applyMiddleware(thunk));
const persistor = persistStore(reduxStore);

export { reduxStore, persistor };
