import firebase from 'firebase';

export const firebaseConfig = {
    apiKey: "AIzaSyDUpKmr5ZjrAeEVa4u8TVfhAAKOLSGpmkE",
    authDomain: "[naavoinc.firebaseapp.com](http://naavoinc.firebaseapp.com/)",
    databaseURL: "[https://naavoinc.firebaseio.com](https://naavoinc.firebaseio.com/)",
    projectId: "naavoinc",
    storageBucket: "[naavoinc.appspot.com](http://naavoinc.appspot.com/)",
    messagingSenderId: "60561757989",
    appId: "1:60561757989:web:5ca752d82dc0c1d71beccc",
    measurementId: "G-Q1L75P0KSD"
};

export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();