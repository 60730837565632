// @ts-nocheck
import React from 'react';
import { Helmet } from "react-helmet";
import { Button as AntdButton, Collapse, Input, Switch, List, Layout, Breadcrumb, notification, Modal, Spin } from 'antd';
import shortID from 'shortid';
import { formatINR, formatQuantity, getCheckoutTitle, LogError, roundValue, toTitleCase } from '../utils';
import { Header } from './Header';
import { Footer } from './Footer';
import { useHistory } from 'react-router';
import { Counter } from './counter';
import { Company, Item, Metadata } from '../store';
import useWindowDimensions from './Home';
import { ValidateField } from '../utils/validation';
import { toDateString, toUTCDate, toUTCDate_New, toYearDateMonth } from '../utils/date';
import { Button } from './Button';
import { placeOrderInDatabase } from '../firebase/storage';
import common from '../styles/common';

const { Panel } = Collapse;
const { Content } = Layout;

interface State {
  pickupSelected: boolean;
  deliverySelected: boolean;
  cart: { [key: string]: Item };
  items: Item[];
  metadata: Metadata;
  company: Company;
  matches: any;
  senderDetails: any;
  errorState: any;
  orderStatus: string;
  loading: boolean;
  orderId: string | undefined;
}

const initialState: State = {
  pickupSelected: false,
  deliverySelected: true,
  cart: {},
  items: [],
  metadata: {},
  company: {},
  matches: undefined,
  senderDetails: {},
  errorState: {},
  orderStatus: '',
  loading: false,
  orderId: undefined
};

interface ListItemProperties {
  imageUri?: string;
  title: string;
  subTitle?: any;
  rightTitle: string;
  rightTitleStyle?: any;
  isRemovable?: boolean;
  itemProperties?: Item;
  isUpdatable?: boolean;
}

export const Checkout = React.memo((props: any) => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const mediaMatch = window.matchMedia('(max-width: 541px)');

  const isPreview = history && history.location && history.location.pathname ? history.location.pathname.indexOf('preview') > -1 : false;
  const isSample = history && history.location && history.location.pathname ? history.location.pathname.indexOf('sample') > -1 : false;

  const commonStyles = history.location.state && history.location.state.data.commonStyles;
  const storeTheme = history.location.state && history.location.state.data.theme;
  const storeAlignment = history.location.state && history.location.state.data.alignment;
  const storeButtonType = history.location.state && history.location.state.data.buttonType;

  const checkoutStyle = React.useMemo(() => commonStyles ? getCheckoutStyle(commonStyles) : {}, [commonStyles]);
  const LayoutStyle = React.useMemo(() => commonStyles ? getLayoutStyle(commonStyles) : {}, [commonStyles]);
  const stateData = React.useMemo(() => {
    const deliveryEnabled = history.location.state && history.location.state.data.metadata ? (history.location.state.data.metadata.deliveryEnabled || false) : false;
    const pickupEnabled = history.location.state && history.location.state.data.metadata ? (history.location.state.data.metadata.pickupEnabled || false) : false;

    return {
      ...initialState,
      cart: history.location.state ? history.location.state.data.cart : {},
      items: history.location.state ? history.location.state.data.items : [],
      metadata: history.location.state ? history.location.state.data.metadata : {},
      company: history.location.state ? history.location.state.data.company : {},
      matches: mediaMatch.matches,
      pickupSelected: !deliveryEnabled,
      deliverySelected: deliveryEnabled
    };
  }, [initialState, history, mediaMatch]);

  const [state, setState] = React.useState(stateData);

  const items = React.useMemo(() => getData(state, commonStyles), [state, commonStyles]);
  const onHome = React.useCallback((e: any) => history.goBack(), [history]);
  const onMediaHandler = React.useCallback((e) => setState((state: any) => {
    return {
      ...state,
      matches: e.matches
    }
  }), [setState]);
  const onUpdateCart = React.useCallback((e, value: any, item: Item) => {
    setState((state: any) => {
      return {
        ...state,
        cart: {
          ...(state.cart),
          [item.id]: {
            ...item,
            orderQuantity: value
          }
        }
      };
    });
  }, [setState]);
  const onRemoveProductFromCart = React.useCallback((e, item: Item) => {
    setState((state: any) => {
      let cart = {};
      for (const key in state.cart) {
        if (key !== item.id) {
          cart[key] = state.cart[key];
        }
      }

      return {
        ...state,
        cart
      };
    });
  }, [setState]);
  const onPlaceOrder = React.useCallback(() => placeOrder(state, setState), [state, setState]);

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace(history.location.pathname, /* the new state */);
      }
    };
  }, [history]);

  React.useEffect(() => {
    mediaMatch.addListener(onMediaHandler);
    return () => {
      mediaMatch.removeListener(onMediaHandler);
    };
  }, [mediaMatch, onMediaHandler]);

  React.useEffect(() => {
    if (Object.keys(state.cart).length === 0) {
      notification.open({
        message: <div style={{ color: commonStyles.footer.fontColor, }}>{`You have no more products in the cart. Please, add products to checkout`}</div>,
        style: { backgroundColor: commonStyles.footer.backgroundDarker, fontFamily: commonStyles.fontfamily, fontSize: commonStyles.fontSize.medium }
      });
    }
  }, [state.cart, history, commonStyles]);

  if (!history.location.state) {
    window.location.href = 'https://naavo.in';
    return null;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Proudly created with Naavo" />
        <title>{`${state.company.name} - Online Store`}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Spin spinning={state.loading} size={'large'}>
        <Layout>
          <Header
            hideSearchIcon
            hideCartIcon
            isSearchVisible={false}
            company={state.company}
            metadata={state.metadata}
            onTitleClick={onHome}
            commonStyles={commonStyles}
          />
          <Content className="site-layout" style={!state.matches ? { ...LayoutStyle.layoutContent } : { ...LayoutStyle.layoutContent, padding: '0 20px' }}>
            <Breadcrumb style={LayoutStyle.breadCrumb}>
              <Breadcrumb.Item onClick={onHome}>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Checkout</Breadcrumb.Item>
            </Breadcrumb>
            <div style={checkoutStyle.root}>
              <div style={checkoutStyle.roottitle}>Checkout</div>
              <div style={state.matches ? { ...checkoutStyle.rootBody, flexDirection: 'column' } : { ...checkoutStyle.rootBody }}>
                <CheckoutOrder
                  matches={state.matches}
                  metadata={state.metadata}
                  senderDetails={state.senderDetails}
                  items={items.items}
                  total={items.totalAmount}
                  onPlaceOrder={isPreview || isSample ? undefined : onPlaceOrder}
                  onUpdateCart={onUpdateCart}
                  onRemoveProductFromCart={onRemoveProductFromCart}
                  isPreview={isPreview || isSample}
                  storeTheme={storeTheme}
                  storeAlignment={storeAlignment}
                  commonStyles={commonStyles}
                  checkoutStyle={checkoutStyle}
                  buttonType={storeButtonType}
                />
                <CheckoutBillingShipping 
                  checkoutStyle={checkoutStyle} 
                  matches={state.matches} 
                  setState={setState} 
                  state={state} 
                  commonStyles={commonStyles} 
                  total={items.totalAmount}
                  onPlaceOrder={isPreview || isSample ? undefined : onPlaceOrder}
                  isPreview={isPreview || isSample}
                  buttonType={storeButtonType}
                  storeAlignment={storeAlignment}
                  metadata={state.metadata}
                />
              </div>
            </div>
          </Content>
          <Footer theme={storeTheme} width={width} metadata={state.metadata} company={state.company} commonStyles={commonStyles} />
        </Layout>
      </Spin>
      <Modal
        visible={state.orderStatus != ''}
        closable={false}
        footer={[]}
        width={500}
        bodyStyle={{ backgroundColor: commonStyles.backgroundColor }}
      >
        <div style={{ maxHeight: 600, padding: commonStyles.gridSize, fontFamily: commonStyles.fontfamily, overflow: 'scroll', top: 0, left: 0 }}>
          <div style={{ fontSize: commonStyles.fontSize.midlarge, color: commonStyles.fontColor.dark, padding: commonStyles.gridSize }}>
            {state.orderStatus == 'error'
              ? `We are really sorry ${toTitleCase(state.senderDetails.name || '')}. We incurred an issue while placing your order. Please, try again later`
              : `Thank you ${toTitleCase(state.senderDetails.name || '')} for your order. Your order has been successfully placed.`}
          </div>
          {state.orderStatus == 'Success' ? <CheckoutOrderSummary
            checkoutStyle={checkoutStyle}
            commonStyles={commonStyles}
            matches={state.matches}
            items={items.items}
            orderId={state.orderId}
            buttonType={storeButtonType}
            state={state}
          /> : null}
          <div style={{
            cursor: 'pointer',
            borderWidth: 1,
            width: 180,
            borderStyle: 'solid',
            borderColor: commonStyles.fontColor.light,
            fontSize: commonStyles.fontSize.midlarge,
            color: commonStyles.fontColor.dark,
            padding: commonStyles.gridSize * 2,
            marginBottom: commonStyles.gridSize * 2,
            textAlign: 'center',
            alignSelf: 'center'
          }} onClick={onHome}>
            {'Continue Shopping'}
          </div>
        </div>
      </Modal>
    </>
  );
});

const CheckoutOrderSummary = React.memo((props: any) => {
  const items = React.useMemo(() => {
    const updateItems = [];
    for (const item of props.items) {
      updateItems.push({
        ...item,
        isUpdatable: false,
        isRemovable: false
      });
    }
    return updateItems;
  }, [props.items]);

  return (
    <div style={{ fontSize: props.commonStyles.fontSize.midlarge, color: props.commonStyles.fontColor.dark, padding: props.commonStyles.gridSize }}>
      <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', margin: 0, padding: 0, fontSize: props.commonStyles.fontSize.large, color: props.commonStyles.fontColor.dark }}>{'Your Order Summary'}</p>
      <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', margin: 0, padding: 0, fontSize: props.commonStyles.fontSize.midlarge }}>{`Order Id: ${props.orderId}`}</p>
      <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', margin: 0, marginBottom: 10, padding: 0, fontSize: props.commonStyles.fontSize.midlarge }}>{`Order Date: ${toDateString(toUTCDate_New())}`}</p>
      <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', padding: 0, fontSize: props.commonStyles.fontSize.midlarge, color: props.commonStyles.fontColor.dark }}>{getCheckoutTitle(props.buttonType)}</p>
      <CheckoutList
        checkoutStyle={{
          ...props.checkoutStyle,
          itemBody: {
            ...props.checkoutStyle.itemBody,
            padding: 0
          }
        }}
        commonStyles={props.commonStyles}
        matches={props.matches}
        items={items}
        onUpdateCart={undefined}
        onRemoveProductFromCart={undefined}
      />
      <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', margin: 0, padding: 0, fontSize: props.commonStyles.fontSize.large, color: props.commonStyles.fontColor.dark }}>{'Payment'}</p>
      <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', padding: 0, fontSize: props.commonStyles.fontSize.midlarge }}>{`Cash on Delivery`}</p>
      {(props.state.metadata.pickupEnabled && props.state.pickupSelected) || (props.state.metadata.deliveryEnabled && props.state.deliverySelected)
        ? <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', padding: 0, fontSize: props.commonStyles.fontSize.large, color: props.commonStyles.fontColor.dark }}>{'Shipping'}</p>
        : null}
      {props.state.metadata.pickupEnabled && props.state.pickupSelected && <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', padding: 0, fontSize: props.commonStyles.fontSize.midlarge }}>{'Mode: Pick up'}</p>}
      {props.state.metadata.deliveryEnabled && props.state.deliverySelected && <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', padding: 0, fontSize: props.commonStyles.fontSize.midlarge }}>{'Mode: Delivery'}</p>}
      {props.state.metadata.deliveryEnabled && props.state.deliverySelected && <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', padding: 0, fontSize: props.commonStyles.fontSize.midlarge }}>{`Delivery Address: ${props.state.senderDetails.streetAddress},\n${props.state.senderDetails.city},${props.state.senderDetails.state}-${props.state.senderDetails.zipcode}`}</p>}
      {props.state.metadata.deliveryEnabled && props.state.deliverySelected && props.state.metadata.DeliveryInstruction && <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', padding: 0, fontSize: props.commonStyles.fontSize.midlarge }}>{`Delivery Instruction: ${props.state.metadata.DeliveryInstruction}`}</p>}
      {props.state.metadata.pickupEnabled && props.state.pickupSelected && <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', padding: 0, fontSize: props.commonStyles.fontSize.midlarge }}>{`Pickup Address: ${props.state.metadata.PickupAddress}`}</p>}
      {props.state.metadata.pickupEnabled && props.state.pickupSelected && props.state.metadata.PickupInstruction && <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', padding: 0, fontSize: props.commonStyles.fontSize.midlarge }}>{`Pickup Instruction: ${props.state.metadata.PickupInstruction}`}</p>}
      <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', margin: 0, padding: 0, fontSize: props.commonStyles.fontSize.large, color: props.commonStyles.fontColor.dark }}>{'Contact'}</p>
      <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', padding: 0, fontSize: props.commonStyles.fontSize.midlarge }}>{`Phone No: ${props.state.senderDetails.phone}`}</p>
      {props.state.senderDetails.email ? <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', margin: 0, padding: 0, fontSize: props.commonStyles.fontSize.midlarge }}>{`Email: ${props.state.senderDetails.email}`}</p> : null}
    </div>
  );
});

const CheckoutOrder = React.memo((props: any) => {
  return (
    <div style={props.matches
      ? { ...props.checkoutStyle.rootBodyChild, paddingHorizontal: 0, paddingTop: props.commonStyles.gridSize * 2 }
      : { ...props.checkoutStyle.rootBodyChild, padding: props.commonStyles.gridSize * 3 }}>
      <p style={{ ...props.checkoutStyle.roottitle, textAlign: 'left', padding: 0, fontSize: props.commonStyles.fontSize.extraLarge }}>{getCheckoutTitle(props.buttonType)}</p>
      <CheckoutList
        checkoutStyle={props.checkoutStyle}
        commonStyles={props.commonStyles}
        matches={props.matches}
        items={props.items}
        onUpdateCart={props.onUpdateCart}
        onRemoveProductFromCart={props.onRemoveProductFromCart}
      />
    </div>
  );
});

const CheckoutList = React.memo((props: any) => {
  const onRenderItem = React.useCallback((item: ListItemProperties) => {
    return (
      <List.Item style={{ width: '100%', ...props.checkoutStyle.itemBody, }}>
        <div>
          <p style={!props.matches ? props.checkoutStyle.itemTitle : { ...props.checkoutStyle.itemTitle, fontSize: props.commonStyles.fontSize.midlarge }}>{item.title}</p>
          {item.subTitle}
        </div>
        <div style={props.checkoutStyle.itemRightBody}>
          <div style={{ paddingRight: 4 }}>
            <p style={!props.matches ? { ...props.checkoutStyle.itemRightTitle, ...(item.rightTitleStyle || {}) } : { ...props.checkoutStyle.itemRightTitle, fontSize: props.commonStyles.fontSize.midlarge, ...(item.rightTitleStyle || {}) }}>{item.rightTitle}</p>
            {item.isUpdatable && <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Counter commonStyles={props.commonStyles} item={item.itemProperties} selectedQuantity={item.itemProperties.orderQuantity} onUpdateCart={props.onUpdateCart} />
              </div>}
          </div>
          {item.isRemovable && <AntdButton 
            type="ghost" 
            size={'small'} 
            style={{ borderColor: 'grey', color: props.commonStyles.fontColor.dark }} 
            onClick={(e) => props.onRemoveProductFromCart(e, item.itemProperties)}>
            X 
          </AntdButton>}
        </div>
      </List.Item>
    );
  }, [props]);

  return (
    <List
      itemLayout="horizontal"
      dataSource={props.items}
      style={props.checkoutStyle.list}
      renderItem={onRenderItem}
    />
  );
});

const CheckoutBillingShipping = React.memo((props: any) => {
  let totalValue = props.total || 0;
  let isMinimumCheckoutAmountMet = props.metadata.MinimumCheckout ? (totalValue > Number(props.metadata.MinimumCheckout)) : true;

  const onPickup = React.useCallback((e) => {
    props.setState((state: State) => {
      return {
        ...state,
        pickupSelected: !state.pickupSelected,
        deliverySelected: state.pickupSelected
      };
    });
  }, [props]);
  const onDelivery = React.useCallback((e) => {
    props.setState((state: State) => {
      return {
        ...state,
        deliverySelected: !state.deliverySelected,
        pickupSelected: state.deliverySelected
      };
    });
  }, [props]);

  return (
    <div style={props.matches
      ? { ...props.checkoutStyle.rootBodyChild, paddingHorizontal: 0, paddingTop: props.commonStyles.gridSize * 2 }
      : { ...props.checkoutStyle.rootBodyChild, padding: props.commonStyles.gridSize * 3 }}>
      <Collapse defaultActiveKey={['1', '2', '3']}>
        <Panel
          header={<p style={{ marginBottom: 0, color: props.commonStyles.fontColor.light }}>Billing</p>}
          key="1"
          style={props.checkoutStyle.panelItem}
        >
          <CheckoutFormInput
            setState={props.setState}
            state={props.state}
            ekey='name'
            label={'Name'}
            validator={'AlphaRegex'}
            tooltip="Your Name is required"
            placeholder="Name"
            maxLength={30}
            checkoutStyle={props.checkoutStyle}
            commonStyles={props.commonStyles}
            required={true}
          />
          <CheckoutFormInput
            setState={props.setState}
            state={props.state}
            ekey='streetAddress'
            label={'Street Address'}
            validator={'AddressRegex'}
            tooltip="Your Street Address is required"
            placeholder="House number and street name"
            maxLength={100}
            checkoutStyle={props.checkoutStyle}
            commonStyles={props.commonStyles}
            required={true}
          />
          <CheckoutFormInput
            setState={props.setState}
            state={props.state}
            ekey='city'
            label={'City'}
            validator={'AlphaRegex'}
            tooltip="Your City is required"
            placeholder="City"
            maxLength={30}
            checkoutStyle={props.checkoutStyle}
            commonStyles={props.commonStyles}
            required={true}
          />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CheckoutFormInput
              setState={props.setState}
              state={props.state}
              ekey='state'
              label={'State'}
              validator={'AlphaRegex'}
              tooltip="Your State is required"
              placeholder="State"
              maxLength={30}
              checkoutStyle={props.checkoutStyle}
              commonStyles={props.commonStyles}
              required={true}
            />
            <CheckoutFormInput
              setState={props.setState}
              state={props.state}
              ekey='zipcode'
              label={'ZIP Code'}
              validator={'ZipCodeRegex'}
              tooltip="Your Zipcode is required"
              placeholder="Zip code"
              maxLength={6}
              checkoutStyle={props.checkoutStyle}
              commonStyles={props.commonStyles}
              required={true}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CheckoutFormInput
              setState={props.setState}
              state={props.state}
              ekey='phone'
              label={'Phone'}
              validator={'PhoneRegex'}
              tooltip="Your Phone number is required"
              placeholder="Phone number"
              maxLength={10}
              checkoutStyle={props.checkoutStyle}
              commonStyles={props.commonStyles}
              required={true}
            />
            <CheckoutFormInput
              setState={props.setState}
              state={props.state}
              ekey='email'
              label={'Email Address'}
              validator={'email'}
              tooltip="Your Email Address is required"
              placeholder="Email Address"
              maxLength={30}
              checkoutStyle={props.checkoutStyle}
              commonStyles={props.commonStyles}
            />
          </div>
          {props.state.errorState.all ? <div style={{ color: props.commonStyles.fontColor.error }}>{props.state.errorState.all}</div> : null}
        </Panel>
        <Panel header={<p style={{ marginBottom: 0, color: props.commonStyles.fontColor.light }}>Payment</p>} key="2" style={props.checkoutStyle.panelItem}>
          <div style={props.checkoutStyle.itemBody}>
            <p style={{ paddingLeft: 4 }}>Payment Mode</p>
            <p style={{ textAlign: 'right', fontWeight: 'normal', paddingRight: 4 }}>{'Cash on Delivery'}</p>
          </div>
          {
            props.state.CODInstruction && <div style={props.checkoutStyle.itemBody}>
              <p style={{ paddingLeft: 4 }}>Payment Instruction</p>
              <p style={{ textAlign: 'right', fontWeight: 'normal', paddingRight: 4 }}>{props.state.metadata.CODInstruction}</p>
            </div>
          }
        </Panel>
        {props.state.metadata.pickupEnabled || props.state.metadata.deliveryEnabled
          ? <Panel header={<p style={{ marginBottom: 0, color: props.commonStyles.fontColor.light }}>Shipping</p>} key="3" style={props.checkoutStyle.panelItem}>
            {props.state.metadata.pickupEnabled ?
              <>
                <div style={props.checkoutStyle.itemBody}>
                  <p style={{ paddingLeft: 4 }}>Pick up</p>
                  <Switch
                    checked={!props.state.metadata.deliveryEnabled ? true : props.state.pickupSelected}
                    onChange={!props.state.metadata.deliveryEnabled ? () => { } : onPickup}
                  />
                </div>
                {
                  (!props.state.metadata.deliveryEnabled || props.state.pickupSelected)
                    ? <>
                      <div style={props.checkoutStyle.itemBody}>
                        <p style={{ flex: 2 }}>Pick up Address</p>
                        <p style={{ flex: 2, textAlign: 'right', fontWeight: 'normal' }}>{props.state.metadata.PickupAddress}</p>
                      </div>
                      <div style={props.checkoutStyle.itemBody}>
                        <p style={{ flex: 2 }}>Pick up Instruction</p>
                        <p style={{ flex: 2, textAlign: 'right', fontWeight: 'normal' }}>{props.state.metadata.PickupInstruction}</p>
                      </div>
                    </>
                    : null
                }
              </>
              : null}
            {props.state.metadata.deliveryEnabled
              ? <>
                <div style={props.checkoutStyle.itemBody}>
                  <p style={{ paddingLeft: 4 }}>Delivery</p>
                  <Switch
                    checked={!props.state.metadata.pickupEnabled ? true : props.state.deliverySelected}
                    onChange={!props.state.metadata.pickupEnabled ? () => { } : onDelivery}
                  />
                </div>
                {
                  (!props.state.metadata.pickupEnabled || props.state.deliverySelected)
                    ? <div style={props.checkoutStyle.itemBody}>
                      <p style={{ flex: 2, paddingLeft: 4 }}>Delivery Instruction</p>
                      <p style={{ flex: 2, paddingRight: 4, textAlign: 'right', fontWeight: 'normal' }}>{props.state.metadata.DeliveryInstruction}</p>
                    </div>
                    : null
                }
              </>
              : null}
          </Panel>
          : null}
      </Collapse>
      {!props.isPreview && <Button
        onClick={props.onPlaceOrder}
        title={isMinimumCheckoutAmountMet ? 'PLACE ORDER' : (`Minimum Checkout Amount: ₹${props.metadata.MinimumCheckout}`)}
        type={'primary'}
        disabled={totalValue === 0 || !isMinimumCheckoutAmountMet}
        commonStyles={props.commonStyles}
        size={'large'}
        styles={{ marginTop: 8 }}
      />}
    </div>
  );
});

const CheckoutFormInput = React.memo((props: any) => {
  const onChange = React.useCallback((e) => {
    props.setState((state: any) => {
      return {
        ...state,
        senderDetails: {
          ...(state.senderDetails),
          [props.ekey]: e.target.value
        }
      };
    });
  }, [props]);
  const onBlur = React.useCallback((e) => {
    const validationResult = ValidateField(props.label, props.state.senderDetails[props.ekey], props.validator, true);
    props.setState((state: any) => {
      const errorState = {
        ...state.errorState,
        [props.ekey]: validationResult.pass ? '' : validationResult.message
      }

      return {
        ...state,
        errorState
      };
    });
  }, [props]);

  return (
    <div tooltip={props.tooltip} style={props.checkoutStyle.formItem}>
      <div>{props.label}</div>
      <Input required={props.required} placeholder={props.placeholder} style={props.checkoutStyle.input} onChange={onChange} onBlur={onBlur} maxLength={props.maxLength} />
      {props.state.errorState && props.state.errorState[props.ekey] && <div style={{ color: props.commonStyles.fontColor.error }}>{props.state.errorState[props.ekey]}</div>}
    </div>
  );
});

function getCheckoutStyle(commonStyles: any) {
  return {
    root: {
      backgroundColor: commonStyles.backgroundColor,
    },
    roottitle: {
      fontFamily: commonStyles.fontfamily,
      fontSize: commonStyles.fontSize.extraextraLarge,
      fontWeight: 'bold',
      width: '100%',
      textAlign: 'center',
      color: commonStyles.fontColor.light
    },
    rootBody: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around'
    },
    rootBodyChild: {
      flex: 1,
      paddingHorizontal: commonStyles.gridSize * 3,
      paddingTop: commonStyles.gridSize * 3,
    },
    panelItem: {
      fontFamily: commonStyles.fontfamily,
      fontWeight: 'bold',
      fontSize: commonStyles.fontSize.midlarge,
      backgroundColor: commonStyles.backgroundColor,
    },
    formItem: {
      flex: 1,
      padding: commonStyles.gridSize,
      fontFamily: commonStyles.fontfamily,
      fontWeight: 'bold',
      fontSize: commonStyles.fontSize.medium,
      backgroundColor: commonStyles.backgroundColor,
      color: commonStyles.fontColor.dark
    },
    list: {
      overflow: 'scroll'
    },
    input: {
      fontFamily: commonStyles.fontfamily,
      fontSize: commonStyles.fontSize.medium,
      padding: commonStyles.gridSize
    },
    itemBody: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontFamily: commonStyles.fontfamily,
      backgroundColor: commonStyles.backgroundColor,
      color: commonStyles.fontColor.dark,
      padding: 0,
      border: 0
    },
    itemRightBody: {
      display: 'flex',
      flexDirection: 'row'
    },
    itemLeftBody: {
      display: 'flex',
      flexDirection: 'row'
    },
    itemTitle: {
      fontSize: commonStyles.fontSize.large,
      fontWeight: 'bold',
      margin: 0
    },
    itemSubTitle: {
      fontFamily: commonStyles.glyphFamily,
      fontSize: commonStyles.fontSize.medium,
      color: commonStyles.fontColor.light,
      lineHeight: 1,
    },
    itemRightTitle: {
      fontFamily: commonStyles.glyphFamily,
      fontWeight: 'bold',
      fontSize: commonStyles.fontSize.midlarge,
      textAlign: 'right'
    }
  };
}

function getLayoutStyle(commonStyles: any) {
  return {
    layoutContent: {
      padding: '0 50px',
      marginTop: 80,
      minHeight: '80vh',
      background: commonStyles.backgroundColor
    },
    root: {
      padding: commonStyles.gridSize
    },
    breadCrumb: {
      fontFamily: commonStyles.fontfamily,
      fontSize: commonStyles.fontSize.midlarge,
      color: commonStyles.fontColor.light,
      padding: commonStyles.gridSize * 2,
      cursor: 'pointer'
    }
  };
}

function isSenderDetailsComplete(senderDetails: any, errorState: any) {
  if (senderDetails && isStringDefined(senderDetails.name)
    && isStringDefined(senderDetails.streetAddress)
    && isStringDefined(senderDetails.city)
    && isStringDefined(senderDetails.state)
    && isStringDefined(senderDetails.zipcode)
    && isStringDefined(senderDetails.phone)
    && isStringDefined(senderDetails.email) && (!errorState.name || errorState.name === '')
    && (!errorState.streetAddress || errorState.streetAddress === '')
    && (!errorState.city || errorState.city === '')
    && (!errorState.state || errorState.state === '')
    && (!errorState.zipcode || errorState.zipcode === '')
    && (!errorState.phone || errorState.phone === '')
    && (!errorState.email || errorState.email === '')) {
    return true;
  }

  return false;
}

function noErrors(errorState: any) {
  if ((!errorState.name || errorState.name === '')
    && (!errorState.streetAddress || errorState.streetAddress === '')
    && (!errorState.city || errorState.city === '')
    && (!errorState.state || errorState.state === '')
    && (!errorState.zipcode || errorState.zipcode === '')
    && (!errorState.phone || errorState.phone === '')
    && (!errorState.email || errorState.email === '')) {
    return true;
  }

  return false;
}

function isStringDefined(str: string) {
  return str !== undefined && str !== null && str !== '';
}

async function placeOrder(state: State, setState: any) {
  try {
    if (!state.senderDetails) {
      setState((state: any) => {
        return {
          ...state,
          errorState: {
            ...(state.errorState || {}),
            'name': 'Required'
          }
        };
      });
      return;
    }

    if (!isStringDefined(state.senderDetails.name)) {
      setState((state: any) => {
        return {
          ...state,
          errorState: {
            ...(state.errorState || {}),
            'name': 'Required'
          }
        };
      });
      return;
    }

    if (!isStringDefined(state.senderDetails.streetAddress)) {
      setState((state: any) => {
        return {
          ...state,
          errorState: {
            ...(state.errorState || {}),
            'streetAddress': 'Required'
          }
        };
      });
      return;
    }

    if (!isStringDefined(state.senderDetails.city)) {
      setState((state: any) => {
        return {
          ...state,
          errorState: {
            ...(state.errorState || {}),
            'city': 'Required'
          }
        };
      });
      return;
    }

    if (!isStringDefined(state.senderDetails.state)) {
      setState((state: any) => {
        return {
          ...state,
          errorState: {
            ...(state.errorState || {}),
            'state': 'Required'
          }
        };
      });
      return;
    }

    if (!isStringDefined(state.senderDetails.zipcode)) {
      setState((state: any) => {
        return {
          ...state,
          errorState: {
            ...(state.errorState || {}),
            'zipcode': 'Required'
          }
        };
      });
      return;
    }

    if (!isStringDefined(state.senderDetails.phone)) {
      setState((state: any) => {
        return {
          ...state,
          errorState: {
            ...(state.errorState || {}),
            'phone': 'Required'
          }
        };
      });
      return;
    }

    if (!noErrors(state.errorState)) {
      setState((state: any) => {
        return {
          ...state,
          errorState: {
            ...(state.errorState || {}),
            'all': 'Please, provide valid details to place an order'
          }
        };
      });
      return;
    }

    setState((state: any) => {
      return {
        ...state,
        errorState: {},
        loading: true
      };
    });

    const metadata = {
      senderDetails: state.senderDetails,
      pickupSelected: state.pickupSelected,
      deliverySelected: state.deliverySelected,
      deliveryCharges: Number(state.metadata.DeliveryCharges || 0),
      gstEnabled: state.metadata.gstEnabled,
      Tax: state.metadata.Tax
    };

    const orderItems = [];
    for (const key in state.cart) {
      orderItems.push({
        orderItemId: key,
        orderItemName: state.cart[key].name,
        orderQuantity: state.cart[key].orderQuantity,
        orderPrice: state.cart[key].price,
        orderItemUnit: state.cart[key].unit,
        cgst: state.cart[key].cgst,
        igst: state.cart[key].igst,
        sgst: state.cart[key].sgst,
      });
    }

    const dateD: Date = toUTCDate(new Date());
    const yearDateMonth = toYearDateMonth(dateD);
    const orderId = shortID.generate();

    const orderDoc = {
      id: orderId,
      isIncoming: true,
      fromOnlineStore: true,
      companyId: state.company.id,
      items: JSON.stringify(orderItems),
      metadata: JSON.stringify(metadata),
      added: dateD,
      orderDate: dateD,
      yearMonth: Number(yearDateMonth.yearMonth),
      dateOfMonth: Number(yearDateMonth.dateOfMonth)
    };

    placeOrderInDatabase({
      collection: 'ONLINE_STORE_ORDERS',
      document: `${orderId}#${state.company.id}`
    }, orderDoc);

    await new Promise(resolve => setTimeout(resolve, 1000));

    setState((state: any) => {
      return {
        ...state,
        loading: false,
        orderStatus: `Success`,
        orderId: orderId
      };
    });
  } catch (exe) {
    LogError(exe);

    setState((state: any) => {
      return {
        ...state,
        loading: false,
        orderStatus: 'error'
      };
    });
  }
}

function getData(state: State, commonStyles: any) {
  if (state.cart && Object.values(state.cart).length > 0) {
    const isGSTEnabled = state.metadata.gstEnabled;
    const taxRate = state.metadata.Tax ? Number(state.metadata.Tax || 0) : 0;
    const saleItems = Object.values(state.cart);
    const deliveryCharges = state.metadata.deliveryEnabled && state.deliverySelected ? Number(state.metadata.DeliveryCharges || 0) : 0;

    let isOutOfState = false;
    if (state.senderDetails && state.senderDetails.state && state.senderDetails.state != state.company.state) {
      isOutOfState = true;
    }

    const items: ListItemProperties = [];
    let subTotal = 0;
    let taxable = 0;

    for (const item in saleItems) {
      const itemD = saleItems[item];
      let imageUri = '';
      if (itemD && itemD.metadata) {
        const metadata = JSON.parse(itemD.metadata);
        if (metadata.downloadImageUri) {
          imageUri = metadata.downloadImageUri;
        }
      }

      const tax = isGSTEnabled ?
        (isOutOfState ? `${'Tax'}: ${'IGST'}: ${itemD.igst || 0}%` : `${'Tax'}: ${'CGST'}: ${itemD.cgst || 0}%, ${'SGST'}: ${itemD.sgst || 0}%`)
        : '';
      let amount = roundValue(Number(itemD.orderQuantity) * Number(itemD.price)) || 0;
      subTotal += amount;

      if (isGSTEnabled) {
        if (isOutOfState && itemD.igst) {
          taxable += roundValue((Number(itemD.igst) * amount) / 100);
        } else if (!isOutOfState && (itemD.cgst || itemD.sgst)) {
          taxable += roundValue((Number(itemD.sgst) * amount) / 100) + roundValue((Number(itemD.cgst) * amount) / 100);
        }
      }

      if (!commonStyles) {
        commonStyles = common();
      }

      items.push({
        imageUri,
        title: itemD.name,
        rightTitle: formatINR(amount),
        subTitle: <>
          <p style={{ color: commonStyles.fontColor.light, margin: 0, fontSize: commonStyles.fontSize.midmedium, fontFamily: commonStyles.glyphFamily }}>{`Price: ${formatINR(roundValue(Number(itemD.price)))}`}</p>
          <p style={{ color: commonStyles.fontColor.light, margin: 0, fontSize: commonStyles.fontSize.midmedium, fontFamily: commonStyles.glyphFamily }}>{`Quantity: ${formatQuantity(roundValue(Number(itemD.orderQuantity)))} ${itemD.unit || ''}`}</p>
          {tax ? <p style={{ color: commonStyles.fontColor.light, margin: 0, fontSize: commonStyles.fontSize.midmedium, fontFamily: commonStyles.glyphFamily, paddingBottom: commonStyles.gridSize }}>{tax}</p> : null}
        </>,
        rightTitleStyle: { fontFamily: commonStyles.glyphFamily }, // removing it to display the sign
        isRemovable: true,
        isUpdatable: true,
        itemProperties: itemD
      });
    }

    items.push({
      title: 'Sub Total',
      rightTitle: formatINR(roundValue(subTotal)),
      rightTitleStyle: { fontFamily: commonStyles.glyphFamily }, // removing it to display the sign
    });

    if (!isGSTEnabled) {
      taxable = roundValue((taxRate * subTotal) / 100);
    }

    items.push({
      title: 'Tax',
      rightTitle: formatINR(roundValue(taxable)),
      rightTitleStyle: { fontFamily: commonStyles.glyphFamily }, // removing it to display the sign
    });

    if (deliveryCharges != 0) {
      items.push({
        title: 'Delivery Charges',
        rightTitle: formatINR(roundValue(deliveryCharges)),
        rightTitleStyle: { fontFamily: commonStyles.glyphFamily }, // removing it to display the sign
      });
    }

    items.push({
      title: 'Total',
      rightTitle: formatINR(roundValue(subTotal + taxable + deliveryCharges)),
      rightTitleStyle: { fontFamily: commonStyles.glyphFamily }, // removing it to display the sign
    });

    return {
      items,
      totalAmount: roundValue(subTotal + taxable + deliveryCharges)
    };
  }

  return {
    items: [],
    totalAmount: 0
  };
}