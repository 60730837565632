import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const dayString = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
export const longDayString = {
   'sun': 'Sunday',
   'mon': 'Monday',
   'tue': 'Tuesday',
   'wed': 'Wednesday',
   'thu': 'Thursday',
   'fri': 'Friday',
   'sat': 'Saturday'
};

const month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
export const noOfDaysInMonth = {
   '01': 31,
   '02': 31,
   '03': 31,
   '04': 30,
   '05': 31,
   '06': 30,
   '07': 31,
   '08': 31,
   '09': 30,
   '10': 31,
   '11': 30,
   '12': 31,
};

export function stringInMonth(localizedStrings: any) {
   return {
      '01': (localizedStrings && localizedStrings.Jan) || 'Jan', 
      '02': (localizedStrings && localizedStrings.Feb) || 'Feb', 
      '03': (localizedStrings && localizedStrings.Mar) || 'Mar', 
      '04': (localizedStrings && localizedStrings.Apr) || 'Apr', 
      '05': (localizedStrings && localizedStrings.May) || 'May', 
      '06':  (localizedStrings && localizedStrings.June) || 'June', 
      '07': (localizedStrings && localizedStrings.July) || 'July', 
      '08': (localizedStrings && localizedStrings.Aug) || 'Aug', 
      '09': (localizedStrings && localizedStrings.Sep) || 'Sep', 
      '10': (localizedStrings && localizedStrings.Oct) || 'Oct',
      '11': (localizedStrings && localizedStrings.Nov) || 'Nov',
      '12': (localizedStrings && localizedStrings.Dec) || 'Dec'
   };
};

export const TimeZones = {
   Indian: "Asia/Calcutta"
};

export const languageLocales: any = {
   'hindi': 'hi-IN',
   'english': 'en-US'
};

export const twoDatesEqual = (date_1: Date, date_2: Date) => {
   if (!date_1 || !date_2) {
      return false;
   }

   if (date_1.getDate() !== date_2.getDate()) {
      return false;
   }

   if (date_1.getMonth() !== date_2.getMonth()) {
      return false;
   }

   return date_1.getFullYear() === date_2.getFullYear();
};

export const toMonthYearString = (date: Date) => {
   const localDate = toLocalDateFromDate(date);
   return `${toMonthName(localDate.getMonth() + 1)} ${localDate.getFullYear()}`
};

export const toUTCDate = (date: Date) => {
   return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())); 
};

export const toStartUTCDate = (date: Date) => {
   return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1)); 
};

export const toEndUTCDate = (date: Date) => {
   return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)); 
};

export const toDateString = (date: Date, language?: string) => {
   if (date === undefined || date === null) {
      return date;
   }

   const localDate = toLocalDateFromDate(date);
   return language ? localDate.toLocaleDateString(languageLocales[language], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : localDate.toDateString();
};

export const toTimeString = (date: Date) => {
   const localDate = toLocalDateFromDate(date);
   return localDate.toTimeString();
};

export const toLocalDateString = (date: Date) => {
   return date.toLocaleDateString(["en-US"], { timeZone: TimeZones.Indian });
};

export const toLocaleString = (date: Date) => {
   return date.toLocaleString(["en-US"], { timeZone: TimeZones.Indian });
};

export const toLocalDate = () => {
   return new Date(toLocalDateString(new Date()));
};

export const toLocalDateFromDate = (date: Date) => {
   return new Date(toLocalDateString(date));
};

export const toLocalDateFromLocalDateString = (localDateString: string) => {
   return new Date(localDateString);
};

export const toYearDateMonth = (date: Date) => {
   const localDate = new Date(date.toLocaleDateString("en-US", { timeZone: TimeZones.Indian }));
   const yearMonth = localDate.getFullYear() + '' + month[localDate.getMonth()];
   const dateOfMonth = localDate.getDate() + '';

   return {
      yearMonth,
      dateOfMonth
   }
};

export const toHumanReadableFormat = (date: Date, currentDate: Date) => {
   const datesDiff = twoDatesDiff(date, currentDate);
   return `${datesDiff}d`;
};

export const toActivityDateString = (dat: any) => {
   if (dat.dayOfMonth && dat.monthOfYear && dat.year) {
      return dat.dayOfMonth + ' ' + toMonthName(dat.monthOfYear) + ' ' + dat.year;
   }
}

export const twoDatesDiff = (date: Date, currentDate: Date) => {
   if (currentDate.getTime() < date.getTime()) {
      return 0;
   }
   const timeDiff = currentDate.getTime() - date.getTime();
   return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

export const twoDatesMinutesDiff = (date: number, currentDate: number) => {
   if (currentDate < date) {
      return 0;
   }
   const timeDiff = Math.abs(currentDate - date);
   return Math.round(((timeDiff % 86400000) % 3600000) / 60000);
};

export const twoMonthsDiff = (date: Date, currentDate: Date) => {
   return Math.abs(currentDate.getMonth() - date.getMonth());
};

export const toHumanDateString = (date: string, localizedStrings?: any) => {
   const dateS = date.split('/');
   return `${dateS[1]} ${toMonthName(Number(dateS[0]), localizedStrings)} ${dateS[2]}`;
};

export const onDateValue = () => {
   const d = new Date();
   return `${d.getFullYear()}-${(d.getMonth() + 1)}-${d.getDate()}`;
};

export const toMonthName = (month: number, localizedStrings?: any) => {
   const monthTitle = [
      (localizedStrings && localizedStrings.Jan) || 'Jan', 
      (localizedStrings && localizedStrings.Feb) || 'Feb', 
      (localizedStrings && localizedStrings.Mar) || 'Mar', 
      (localizedStrings && localizedStrings.Apr) || 'Apr', 
      (localizedStrings && localizedStrings.May) || 'May', 
      (localizedStrings && localizedStrings.June) || 'June', 
      (localizedStrings && localizedStrings.July) || 'July', 
      (localizedStrings && localizedStrings.Aug) || 'Aug', 
      (localizedStrings && localizedStrings.Sep) || 'Sep', 
      (localizedStrings && localizedStrings.Oct) || 'Oct',
      (localizedStrings && localizedStrings.Nov) || 'Nov', 
      (localizedStrings && localizedStrings.Dec) || 'Dec'
   ];
   return monthTitle[month - 1];
}

export const dateDiff = (date: Date) => {
   const date2 = new Date();
   date2.setHours(0, 0, 0, 0);
   if (date2.getTime() < date.getTime()) {
      return 0;
   }
   const timeDiff = Math.abs(date2.getTime() - date.getTime());
   return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

export const asMonthDiff = (date: Date) => {
   const date2 = new Date();
   return Math.abs(date2.getMonth() - date.getMonth());
};

export const asMonthYear = (date: string) => {
   const dateS = date.split('-');
   return `${toMonthName(Number(dateS[1]))}${dateS[0]}`;
};

export function toCurrentYearDateMonth(): string {
   const localDate = new Date(new Date().toLocaleDateString("en-US", { timeZone: TimeZones.Indian }));
   return localDate.getFullYear() + '' + month[localDate.getMonth()];
};

export function toPreviousYearMonth(currentYearMonth: string) {
   const year = currentYearMonth.substr(0, 4);
   const mnth = currentYearMonth.substr(4, 2);

   if (mnth === '01') {
      return `${Number(year) - 1}12`; 
   } 

   return `${year}${month[Number(mnth) - 2]}`;
}

export const toUTCDate_New = () => {
   return dayjs.utc().toDate();
}

export const toCloneDate = (date: Date) => {
   return dayjs(date).clone().toDate();
}

export const toDateDiff = (date: Date, value: number, type: 'day' | 'month' | 'year') => {
   return dayjs(date).subtract(value, type).toDate();
}

export const toDateAdd = (date: Date, value: number, type: 'day' | 'month' | 'year') => {
   return dayjs(date).add(value, type).toDate();
}

export const toTimeDiff = (date: Date, value: number, type: 'hour' | 'minute' | 'second') => {
   return dayjs(date).subtract(value, type).toDate();
}

export const toStartOfDate = (date: Date, type: 'day' | 'month' | 'year' | 'hour') => {
   return dayjs(date).startOf(type).toDate();
}

export const toDatesEqual_New = (date_1: Date, date_2: Date) => {
   return dayjs(date_1).isSame(date_2);
}

export const isDateAfter = (currentDate: Date, afterDate: Date) => {
   return dayjs(currentDate).isAfter(afterDate);
}

export const isDateBefore = (currentDate: Date, beforeDate: Date) => {
   return dayjs(currentDate).isBefore(beforeDate);
}

export const toEndDay = (date: Date) => {
   return dayjs(date).endOf('day').toDate();
}

export const toCurrentDate = (date: Date) => {
   const toStartDate = toStartOfDate(date, 'day');
   const toCurrentStartDate = toStartOfDate(toUTCDate_New(), 'day');

   const datesDiff = twoDatesDiff(toStartDate, toCurrentStartDate);
   const nwDate = toDateAdd(date, datesDiff, 'day');

   return nwDate;
}

export const toCurrentLocalDate = () => {
   return dayjs().tz(TimeZones.Indian).toDate();
}

export const toDate = (date: Date | string | number) => {
   return dayjs(date).toDate();
}