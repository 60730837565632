/*eslint eqeqeq: "off"*/
import { Card, Image, Skeleton } from 'antd';
import React from 'react';
import { fallbackImage } from '../data';
import { Button } from './Button';
import { Item } from '../store';
import { formatINR, getButtonType, isListView, toTitleCase } from '../utils';
import Meta from 'antd/lib/card/Meta';

interface Properties {
    item: Item;
    windowWidth: number;
    cardstyle?: any;
    cardBodyStyle?: any;
    imageStyle?: any;
    loading?: boolean;
    onCardClick: (e: any) => void;
    onAddToCardClick: (e: any) => void;
    commonStyles: any;
    alignment: any;
    buttonType: any;
}

export const ItemCard = React.memo((props: Properties) => {
    const dynamicCardStyle = props.cardstyle || {};
    const dynamicCardBodyStyle = props.cardBodyStyle || {};
    const dynamicImageStyle = props.imageStyle || {};
    const showButton = true; //props.alignment != 0;

    const ItemCardStyle = React.useMemo(() => getItemCardStyle(props.commonStyles), [props.commonStyles]);
    const isListViewEnabled = isListView(props.alignment);

    let imageUri = '';
    if (props.item.metadata) {
        const metadata = JSON.parse(props.item.metadata);
        imageUri = metadata.downloadImageUri;
    }

    return (
        <div>
            <Card
                hoverable
                bordered
                style={{
                    ...ItemCardStyle.cardRoot,
                    ...dynamicCardStyle,
                    borderWidth: 1,
                    borderColor: props.commonStyles.fontColor.light,
                    maxWidth: props.alignment != 2 ? 300 : undefined,
                    width: props.alignment == 2 ? 300 : undefined
                }}
                bodyStyle={{
                    ...ItemCardStyle.cardBody,
                    ...dynamicCardBodyStyle,
                    background: props.commonStyles.backgroundColor,
                }}
            >
                <Skeleton loading={props.loading} active>
                    {isListViewEnabled ? <>
                        <Meta
                            avatar={<Image
                                height={200}
                                width={140}
                                src={imageUri}
                                style={{ 
                                    ...ItemCardStyle.imageRoot, 
                                    ...dynamicImageStyle, 
                                    padding: props.commonStyles.gridSize, 
                                    paddingBottom: 0,
                                    cursor: 'pointer'
                                }}
                                fallback={fallbackImage}
                                preview={false}
                                onClick={props.onCardClick}
                            />}
                            description={<div style={{ 
                                    textAlign: 'center', 
                                    padding: props.commonStyles.gridSize * 1.5, 
                                    background: props.commonStyles.backgroundColor,
                                    height: 180,
                                    marginTop: props.commonStyles.gridSize,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'column'
                                }}>
                                <div>
                                    <div style={{ fontSize: props.commonStyles.fontSize.large, color: props.commonStyles.fontColor.dark, }}>{props.item.name}</div>
                                    <div style={{ background: props.commonStyles.backgroundColor, fontFamily: props.commonStyles.glyphFamily, fontSize: props.commonStyles.fontSize.midlarge, color: props.commonStyles.fontColor.light, padding: props.commonStyles.gridSize }}>{formatINR(props.item.price || 0)}</div>
                                </div>
                                {showButton && <Button
                                    commonStyles={props.commonStyles}
                                    type={props.alignment == 1 ? 'ghost' : 'primary'}
                                    title={props.item.quantity > 0 ? getButtonType(props.buttonType) : 'Out of Stock'}
                                    onClick={props.item.quantity > 0 ? props.onAddToCardClick : undefined}
                                    size={'middle'}
                                    isSmallScreen={props.windowWidth < 575}
                                />}
                            </div>}
                        ></Meta>
                    </> :
                    <>
                        <Image
                            src={imageUri}
                            style={{ 
                                padding: 4,
                                ...ItemCardStyle.imageRoot, 
                                ...dynamicImageStyle, 
                                cursor: 'pointer',
                                maxHeight: 300,
                                maxWidth: 300,
                                minHeight: 300,
                            }}
                            fallback={fallbackImage}
                            preview={false}
                            onClick={props.onCardClick}
                        />
                        <div style={{ 
                            textAlign: 'center', 
                            padding: props.commonStyles.gridSize * 1.5, 
                            background: props.commonStyles.backgroundColor,
                            cursor: showButton ? undefined : 'pointer'
                        }}>
                            <div style={{ fontSize: props.commonStyles.fontSize.large, color: props.commonStyles.fontColor.dark, }}>{toTitleCase(props.item.name)}</div>
                            <div style={{ background: props.commonStyles.backgroundColor, fontFamily: props.commonStyles.glyphFamily, fontSize: props.commonStyles.fontSize.midlarge, color: props.commonStyles.fontColor.light, padding: props.commonStyles.gridSize }}>{formatINR(props.item.price || 0)}</div>
                            {props.item.quantity <= 0 
                                ? <div style={{ fontSize: props.commonStyles.fontSize.large, color: props.commonStyles.fontColor.dark, }}>{'Out of Stock'}</div>
                                : (showButton && <Button
                                    commonStyles={props.commonStyles}
                                    type={props.alignment == 1 ? 'ghost' : 'primary'}
                                    title={props.item.quantity > 0 ? getButtonType(props.buttonType) : 'Out of Stock'}
                                    onClick={props.item.quantity > 0 ? props.onAddToCardClick : undefined}
                                    size={'middle'}
                                    isSmallScreen={props.windowWidth < 575}
                                />)}
                        </div>
                    </>
                    }
                </Skeleton>
            </Card>
        </div>
    );
});

function getItemCardStyle(commonStyles: any) {
    return {
        cardRoot: {
            fontFamily: commonStyles.fontfamily
        },
        cardBody: {
            padding: 0
        },
        imageRoot: {
            paddingBottom: commonStyles.gridSize
        }
    };
}
