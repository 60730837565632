import { loginWithEmailAndPassword } from './auth';
import firebase from 'firebase';
import { LogError } from '../utils';
const axios = require('axios');

export async function getBusinessCardInvoiceData(path: any, type: any) {
  try {
    const storage = firebase.app().storage("gs://naavoinc.appspot.com");
    const storageRef = storage.ref();
    let downloadUrl = await storageRef.child(`${path}`).getDownloadURL();
    const data = await axios.get(downloadUrl);
    return data ? data.data : `<div style={{ padding: 20, text-align: 'center' }}>${type} does not exist. Please, verify again</div>`;
  } catch (exe: any) {
    if (exe && exe.code === 'storage/object-not-found') {
      return `
          <div style={{ padding: 20, text-align: center }}>${type} does not exist. Please, verify again</div>
      `;
    }

    return `<div style={{ padding: 20, text-align: center }}>Error occurred while loading. Please, try again later</div>`;
  }
}

export async function getMetadata(store: any) {
  try {
    const storage = firebase.app().storage("gs://naavoinc.appspot.com");
    const storageRef = storage.ref();
    let downloadUrl = await storageRef.child(`${store}/onlineStore/metadata`).getDownloadURL();
    let storageData = await axios.get(downloadUrl);
    return storageData ? storageData.data : null;
  } catch (exe: any) {
    if (exe && exe.code === 'storage/object-not-found') {
      return null;
    }

    LogError(exe);
    return null;
  }
}

export async function getItems(store: any) {
  try {
    const storage = firebase.app().storage("gs://naavoinc.appspot.com");
    const storageRef = storage.ref();
    let downloadUrl = await storageRef.child(`${store}/onlineStore/Items`).getDownloadURL();
    let storageData = await axios.get(downloadUrl);
    return storageData && storageData.data ? storageData.data.items : null;
  } catch (exe: any) {
    if (exe && exe.code === 'storage/object-not-found') {
      return null;
    }

    LogError(exe);
    return null;
  }
}

export async function getData(store: any) {
  try {
    const user = await loginWithEmailAndPassword();
    if (user) {
      const fetchMetadata = getMetadata(store);
      const fetchItems = getItems(store);

      const fetchedData: [any, any] = await Promise.all([fetchMetadata, fetchItems]);
      if (fetchedData[0] != null && fetchedData[1] != null) {
        let data = {
          metadata: fetchedData[0],
          items: fetchedData[1]
        };
        return data;
      }
    }

    return null;
  } catch (exe: any) {
    if (exe && exe.code === 'storage/object-not-found') {
      return null;
    }

    LogError(exe);
    return null;
  }
}

export async function placeOrderInDatabase(doc: any, data: any) {
  const user = await loginWithEmailAndPassword();
  if (user) {
    return firebase.firestore().runTransaction(async (transaction: any) => {
        const document = firebase.firestore().collection(doc.collection).doc(doc.document);
        await transaction.set(document, data);
    });
  }
}