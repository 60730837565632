export const gridSize = 8;

interface Design {
  background: string;
  fontDark: string;
  fontLight: string;
  buttonBackground: string;
  buttonFontColor: string;
  iconColor: string;
  footerBackgroundDarker: string;
  footerBackgroundLighter: string;
}

function normalize(num: number) {
  return num;
}

function colorPaletter(colorSelection?: number) {
  const paletter = [{
    darker: 'black',
    dark: 'black',
    medium: 'black',
    light: '#0000FF',
    lighter: '#0070FF'
  },{
    darker: '#6b705c',
    dark: '#a5a58d',
    medium: '#b7b7a4',
    light: '#ddbea9',
    lighter: '#ffe8d6'
  },{
    darker: '#6d6875',
    dark: '#b5838d',
    medium: '#e5989b',
    light: '#ffb4a2',
    lighter: '#ffcdb2'
  },{
    darker: '#7f5539',
    dark: '#b08968',
    medium: '#ddb892',
    light: '#e6ccb2',
    lighter: '#ede0d4'
  },{
    darker: '#000000',
    dark: '#14213d',
    medium: '#fca311',
    light: '#999999',
    lighter: '#e5e5e5'
  },{
    darker: '#073b4c',
    dark: '#118ab2',
    medium: '#06d6a0',
    light: '#ffd166',
    lighter: '#ef476f'
  },{
    darker: '#a44a3f',
    dark: '#f19c79',
    medium: '#cbdfbd',
    light: '#d4e09b',
    lighter: '#f6f4d2'
  }, {
    darker: '#fb8500',
    dark: '#023047',
    medium: '#ffb703',
    light: '#219ebc',
    lighter: '#8ecae6'
  },{
    darker: '#283618',
    dark: '#606c38',
    medium: '#bc6c25',
    light: '#dda15e',
    lighter: '#fefae0'
  },{
    darker: '#b388eb',
    dark: '#8093f1',
    medium: '#72ddf7',
    light: '#f7aef8',
    lighter: '#fdc5f5'
  },{
    darker: '#5d737e',
    dark: '#64b6ac',
    medium: '#7de2d1',
    light: '#c0fdfb',
    lighter: '#fffafb'
  }];

  if (!colorSelection || (colorSelection && colorSelection > 6)) {
    return paletter[0];
  }

  return paletter[colorSelection];
}

function fontCombinations(fontSelection?: number) {
  const fontgroup = [{
    sans: 'Apercu-Regular',
    serif: 'Apercu-Regular'
    }, {
    sans: 'CrimsonText-Bold',
    serif: 'SourceSansPro-Bold'
    }, {
    sans: 'Lato-Bold',
    serif: 'Lato-Bold'
    }, {
    sans: 'Montserrat-Medium',
    serif: 'Roboto-Regular'
    }, {
    sans: 'Bitter-Medium',
    serif: 'LuckiestGuy-Regular'
    }, {
    sans: 'LuckiestGuy-Regular',
    serif: 'LuckiestGuy-Regular'
  }];

  if (fontSelection && fontSelection > 5) {
    return fontgroup[0];
  }

  return fontgroup[fontSelection || 0];
}

function designOptions(designSelection?: string, colorSelection?: number) {
  const colorgroup = colorPaletter(colorSelection);
  const design: { [key: string]: Design; } = {
    whiteMinimal: {
      background: 'white',
      fontDark: colorgroup.medium,
      fontLight: colorgroup.lighter,
      buttonBackground: colorgroup.lighter,
      buttonFontColor: colorgroup.medium,
      iconColor: colorgroup.medium,
      footerBackgroundDarker: 'white',
      footerBackgroundLighter: 'white',
    },
    whiteBold: {
      background: 'white',
      fontDark: colorgroup.darker,
      fontLight: colorgroup.medium,
      buttonBackground: colorgroup.darker,
      buttonFontColor: 'white',
      iconColor: colorgroup.darker,
      footerBackgroundDarker: 'white',
      footerBackgroundLighter: 'white',
    },
    lightMinimal: {
      background: colorgroup.lighter,
      fontDark: colorgroup.dark,
      fontLight: colorgroup.medium,
      buttonBackground: colorgroup.dark,
      buttonFontColor: colorgroup.medium,
      iconColor: colorgroup.dark,
      footerBackgroundDarker: colorgroup.dark,
      footerBackgroundLighter: colorgroup.lighter,
    },
    lightBold: {
      background: colorgroup.light,
      fontDark: colorgroup.darker,
      fontLight: colorgroup.lighter,
      buttonBackground: colorgroup.darker,
      buttonFontColor: 'white',
      iconColor: colorgroup.darker,
      footerBackgroundDarker: colorgroup.dark,
      footerBackgroundLighter: colorgroup.dark,
    },
    darkMinimal: {
      background: colorgroup.medium,
      fontDark: colorgroup.dark,
      fontLight: colorgroup.lighter,
      buttonBackground: colorgroup.dark,
      buttonFontColor: colorgroup.lighter,
      iconColor: colorgroup.dark,
      footerBackgroundDarker: colorgroup.dark,
      footerBackgroundLighter: colorgroup.light,
    },
    darkBold: {
      background: colorgroup.dark,
      fontDark: colorgroup.lighter,
      fontLight: colorgroup.medium,
      buttonBackground: colorgroup.lighter,
      buttonFontColor: colorgroup.medium,
      iconColor: colorgroup.lighter,
      footerBackgroundDarker: colorgroup.medium,
      footerBackgroundLighter: colorgroup.lighter,
    }
  };  

  return !designSelection || design[designSelection] === undefined ? design['whiteMinimal'] : design[designSelection];
}

export default function common(designSelection?: string, colorSelection?: number, fontSelection?: number) {
  const fontPairings = fontCombinations(fontSelection);
  const design: Design = designOptions(designSelection, colorSelection);

  return {
    gridSize,
    fontfamily: fontPairings.sans,
    glyphFamily: fontPairings.serif,
    backgroundColor: design.background,
    iconColor: design.iconColor,
    footer: {
      backgroundDarker: design.footerBackgroundDarker,
      backgroundLigher: design.footerBackgroundLighter,
      fontColor: design.fontLight
    },
    fontColor: {
      dark: design.fontDark,
      light: design.fontLight,
      error: '#a94442',
      white: '#FFFFFF',
    },
    fontSize: {
      extraextraLarge: normalize(25),
      extraLarge: normalize(22),
      midextralarge: normalize(19),
      large: normalize(16),
      small: normalize(10),
      medium: normalize(13),
      midmedium: normalize(12),
      midlarge: normalize(15),
    },
    header: {
      height: gridSize * 12.5,
    },
    button: {
      height: gridSize * 11.5,
      fontSize: gridSize * 4,
      backgroundColor: design.buttonBackground,
      margin: gridSize * 5,
      justifyContent: 'center' as const,
      color: design.buttonFontColor,
      padding: gridSize * 3,
    },
    leftAlignment: {
      justifyContent: 'flex-start',
    },
    rightAlignment: {
      justifyContent: 'flex-end',
    },
    centerAlignment: {
      justifyContent: 'center' as const,
    },
    spaceBetweenAlignment: {
      justifyContent: 'space-between' as const,
    },
    alignStyle: {
      flex: 1,
      flexDirection: 'row' as const,
    },
    messageStyle: {
      backgroundColor: design.background,
      strokeColor: '#57BB8A',
      titleColor: design.fontDark,
      messageColor: design.fontLight,
    },
    messageTitleStyle: {
      fontFamily: fontPairings.sans,
      fontSize: gridSize * 4.5,
    },
    messageContentStyle: {
      fontFamily: fontPairings.sans,
      color: design.fontLight,
      fontSize: gridSize * 4,
    },
  };
};
