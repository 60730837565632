import React from 'react';

import { getBusinessCardInvoiceData } from '../firebase/storage';
import { useHistory, useParams } from 'react-router';
import common from '../styles/common';
import { LoadingOutlined } from '@ant-design/icons';

export const BusinessCardInvoice = React.memo(() => {
    const history = useHistory();
    let { businesscard, invoice } = useParams<any>();

    const isBusinessCard = history && history.location && history.location.pathname ? history.location.pathname.indexOf('bcard') > -1 : false;
    const isInvoice = history && history.location && history.location.pathname ? history.location.pathname.indexOf('inv') > -1 : false;
    const commonStyles = React.useMemo(() => {
        let design = 'whiteMinimal';
        let color = 0;
        let font = 0;
        return common(design, color, font);
    }, []);

    React.useEffect(() => {
        if (isBusinessCard || isInvoice) {
            getDetails(isInvoice ? `invoices/${invoice}` : `businesscards/${businesscard}`, isInvoice ? 'Invoice' : 'BusinessCard');
        } else {
            window.location.href = 'https://naavo.in';
        }
    }, [businesscard, invoice, isBusinessCard, isInvoice]);

    document.title = `Loading ${isBusinessCard ? 'business card' : 'invoice'}...`;
    document.body.style.backgroundColor = '#a8dba8';

    return (
        <> 
            <LoadingOutlined style={{ display: 'flex', color: '#1d3557', fontSize: 30, flex: 1, marginTop: '8%', justifyContent: 'center' }} />
            <div style={{ padding: 20, textAlign: 'center', fontFamily: commonStyles.fontfamily, fontSize: commonStyles.fontSize.extraLarge }}>{`Loading ${isBusinessCard ? 'business card' : 'invoice'}`}</div>
            <div style={{ padding: 20, textAlign: 'center', fontFamily: commonStyles.fontfamily, fontSize: commonStyles.fontSize.extraLarge }}>{`This ${isBusinessCard ? 'business card' : 'invoice'} is proudly created with Naavo`}</div>
        </>
    );
});

async function getDetails(path: any, type: any) {
   const link = await getBusinessCardInvoiceData(path, type);
   document.write(link)
}