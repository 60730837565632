export function roundValue(value: number) {
    let vl = value;
    if (value === undefined || value === null) {
        vl = 0;
    }

    return Math.round((vl + Number.EPSILON) * 100) / 100;
}

export function formatINR(value: number) {
    let vl = value;
    if (value === undefined || value === null) {
        vl = 0;
    }

    return vl.toLocaleString('en-IN', {style: 'currency', currency: 'INR'});
    //return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value);
}

export function formatQuantity(value: number) {
    let vl = value;
    if (value === undefined || value === null) {
        vl = 0;
    }

    return vl.toLocaleString('en-IN');
}

export function formatPercent(value: number) {
    let vl = value;
    if (value === undefined || value === null) {
        vl = 0;
    }

    return vl.toLocaleString('en-IN', { style: 'percent' });
}

export function formatNumber(value: number) {
    let vl = value;
    if (value === undefined || value === null) {
        vl = 0;
    }

    return vl.toLocaleString('en-IN');
}

