export const toStartCase = (str: string = '') => {
    const trimVal = toTrimValue(str);
    if (trimVal === undefined || trimVal === null || trimVal === '') {
        return trimVal;
    }

    return trimVal.charAt(0).toUpperCase() + '' + trimVal.slice(1, str.length).toLowerCase();
}

export function toTitleCase(str: string = '') {
    const trimVal = toTrimValue(str);
    if (trimVal === undefined || trimVal === null || trimVal === '') {
        return trimVal;
    }

    return trimVal.replace(/\w\S*/g, function (txt: any) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

export function toTrimValue(str: string | undefined) {
    if (str === undefined || str === null || str === '') {
        return str;
    }

    if (typeof str === 'string') {
        return str.trim();
    } else { // this usually occurs when you are sending something other than string. Typescript doesn't have dynamic checks.
        return str;
    }
}

export function parseJSONString(jsonString: string | any) {
    if (jsonString && typeof jsonString === 'string') {
        return JSON.parse(jsonString);
    }

    return jsonString;
}

export function toFirstString(str: string) {
    const trimVal = toTrimValue(str);
    if (trimVal === undefined || trimVal === null || trimVal === '') {
        return trimVal;
    }

    const vl = str.split(' ');
    if (vl.length > 1) {
        return vl[0].charAt(0).toUpperCase() + '' + vl[0].slice(1, vl[0].length).toLowerCase();
    }

    return toStartCase(vl[0]);
}